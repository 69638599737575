import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import type { SelectProps } from 'src/types/elements.type';
import { getTechnicianTypesDropdown } from 'src/apis/master_data/technicianType.api';

export default function TechnicianTypeSelect({ value, style, onChange, returnValueType, ...props }: SelectProps) {
  const techinicianTypesDropdownQuery = useQuery({
    queryKey: ['techinicianTypesDropdown'],
    queryFn: () => getTechnicianTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      value={techinicianTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={techinicianTypesDropdownQuery.data?.data.map((item) => ({
        value: returnValueType === 'code' ? item.code : returnValueType === 'object' ? JSON.stringify(item) : item.id,
        label: `${item.code} - ${item.name}`,
        data: item
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={techinicianTypesDropdownQuery.isFetching}
      {...props}
    />
  );
}
