import type { BaseType } from '../base.type';
import type { FlatItem } from '../elements.type';
import type { IEmployeeWithDepartment } from '../master_data/employee.type';
import type { ContactInfoAdd, IContactInfo } from './contactInfo.type';
import type { IVisitor } from './visitor.type';

export interface IBusinessPartner extends BaseType {
  id: number;
  customerSurveyId: number;
  code: string; // - mã
  businessPartnerGroupId: number;
  customerTypeId: number;
  countryId: number;
  evaluation: string;
  customerSurvey: IVisitor;
  address: string; // - địa chỉ chính
  invoiceAddress: string; // - Địa chỉ hóa đơn
  contactAddress: string; // - Địa chỉ liên lạc
  productionReceiveAddress: string; // - Địa chỉ nhận hàng
  companyName: string; // - Tên đối tác
  parentCompany: string;
  parentCompanyCountryId: number;
  companyPhoneNumber: string; // - Điện thoại công ty
  companyEmail: string; // - Email công ty
  fieldOfBusinessId: number; // - fieldOfBusinessId
  isAssign: boolean;
  assignTo: IEmployeeWithDepartment; //ASSIGN TO
  assignBy: IEmployeeWithDepartment; //ASSIGN BY
  currentSaleMan: string; // - người bán hàng hiện tại
  contactInfos: IContactInfo[];
  businessPartnerGroup: FlatItem;

  representativeName: string; // - Người đại diện
  taxCode: string; // -Tax code
  zipCode: string; // -Zip code
  // hotLine: string;
  bankAccount: string; // - Bank account
  // swiftCode: string;
  dateOfBCDSigned: Date;
  // bankAddress: string;
  brCertOrId: string; // - Giấy phép KD/CCCD

  isHasSaleForm: boolean; // dùng check form bổ sung thông tin
}

export type BusinessPartnerAdd = Omit<Partial<IBusinessPartner>, 'lastModifiedDate' | 'contactInfos'> & {
  contactInfos: ContactInfoAdd[];
};

export type LeadAdd = Pick<
  Partial<IBusinessPartner>,
  | 'id'
  | 'customerTypeId'
  | 'customerSurveyId'
  | 'code'
  | 'businessPartnerGroupId'
  | 'evaluation'
  | 'companyName'
  | 'parentCompany'
  | 'countryId'
  | 'parentCompanyCountryId'
  | 'companyPhoneNumber'
  | 'companyEmail'
  | 'fieldOfBusinessId'
  | 'createdDate'
  | 'assignBy'
  | 'assignTo'
  | 'customerSurvey'
> & {
  contactInfos: ContactInfoAdd[];
};

export type OpportunityAdd = Pick<
  Partial<IBusinessPartner>,
  | 'id'
  | 'customerTypeId'
  | 'code'
  | 'companyName'
  | 'address'
  | 'invoiceAddress'
  | 'contactAddress'
  | 'productionReceiveAddress'
  | 'companyPhoneNumber'
  | 'companyEmail'
  | 'fieldOfBusinessId'
  | 'representativeName'
  | 'currentSaleMan'
  | 'taxCode'
  | 'brCertOrId'
  | 'dateOfBCDSigned'
  | 'bankAccount'
  | 'zipCode'
  | 'createdDate'
  | 'assignBy'
  | 'assignTo'
> & {
  contactInfos: ContactInfoAdd[];
};

export type BusinessPartnerShort = Pick<
  Partial<IBusinessPartner>,
  | 'id'
  | 'code'
  | 'companyName'
  | 'address'
  | 'companyPhoneNumber'
  | 'companyEmail'
  | 'fieldOfBusinessId'
  | 'representativeName'
  | 'currentSaleMan'
>;

export type BusinessPartnerAssign = {
  businessPartnerId?: number;
  assignToId?: number;
};

export enum CustomerTypeEn {
  Lead = 1,
  Opportunity = 2,
  PotentialSupplier = 3,
  Supplier = 4
}
