import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import purchasePath from 'src/constants/urlPath/erp/purchasePath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const Purchase = lazy(() => import('src/pages/Purchase'));

const PurchaseRequest = lazy(() => import('src/pages/ERP/Planning/Pr'));
const AddPurchaseRequest = lazy(() => import('src/pages/ERP/Planning/Pr/AddPurchaseRequest'));

const PQR = lazy(() => import('src/pages/Purchase/PQR'));
const AddPQR = lazy(() => import('src/pages/Purchase/PQR/AddPQR'));
const PurchaseOrder = lazy(() => import('src/pages/Purchase/PurchaseOrder'));
const PurchaseQuotation = lazy(() => import('src/pages/Purchase/PurchaseQuotation'));
const AddPurchaseQuotationVendor = lazy(
  () => import('src/pages/Purchase/PurchaseQuotation/AddPurchaseQuotationVendor')
);
const PurchaseQuotationDetail = lazy(() => import('src/pages/Purchase/PurchaseQuotation/PurchaseQuotationDetail'));
const AddPurchaseOrder = lazy(() => import('src/pages/Purchase/PurchaseOrder/AddPurchaseOrder'));
const GRPOR = lazy(() => import('src/pages/Inventory/GRPOR'));
const AddGRPOR = lazy(() => import('src/pages/Inventory/GRPOR/AddGRPOR'));
const ReceivePlan = lazy(() => import('src/pages/Purchase/ReceivePlan'));
const HistoryOrder = lazy(() => import('src/pages/Purchase/HistoryOrder'));
const DebtGroup = lazy(() => import('src/pages/Purchase/DebtGroup'));
const Debt = lazy(() => import('src/pages/Purchase/Debt'));
const SetOfDocument = lazy(() => import('src/pages/Purchase/SetOfDocument'));
const DocumentType = lazy(() => import('src/pages/Purchase/DocumentType'));
const RefKey = lazy(() => import('src/pages/Purchase/RefKey'));

const PaymentMethod = lazy(() => import('src/pages/ERP/Accounting/PaymentMethod'));
const PaymentMethodForm = lazy(() => import('src/pages/ERP/Accounting/PaymentMethod/PaymentMethodForm'));
const ShippingStage = lazy(() => import('src/pages/ERP/Accounting/ShippingStage'));
const ShippingStageForm = lazy(() => import('src/pages/ERP/Accounting/ShippingStage/ShippingStageForm'));

const purchaseRoutes: RouteType[] = [
  {
    path: purchasePath.purchase,
    Layout: NewLayout,
    Component: Purchase
  },

  // PR - Yêu cầu mua hàng
  {
    path: purchasePath.pr,
    Layout: NewLayout,
    Component: PurchaseRequest,
    permission: PermissionEn.read
  },
  {
    path: `${purchasePath.prDetail}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.pr,
    Component: AddPurchaseRequest,
    permission: PermissionEn.viewDetail
  },
  {
    path: purchasePath.prAdd,
    Layout: NewLayout,
    Component: AddPurchaseRequest,
    checkPath: purchasePath.pr,
    permission: PermissionEn.create
  },
  {
    path: `${purchasePath.prEdit}/:id`,
    Layout: NewLayout,
    Component: AddPurchaseRequest,
    checkPath: purchasePath.pr,
    permission: PermissionEn.update
  },

  // PQR - Yêu cầu báo giá mua hàng
  {
    path: purchasePath.pqr,
    Layout: NewLayout,
    Component: PQR,
    permission: PermissionEn.read
  },
  {
    path: purchasePath.pqrAdd,
    Layout: NewLayout,
    checkPath: purchasePath.pqr,
    Component: AddPQR,
    permission: PermissionEn.create
  },
  {
    path: `${purchasePath.pqrEdit}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.pqr,
    Component: AddPQR,
    permission: PermissionEn.update
  },
  {
    path: `${purchasePath.pqrDetail}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.pqr,
    Component: AddPQR,
    permission: PermissionEn.viewDetail
  },

  // PQ - Báo giá mua hàng
  {
    path: purchasePath.pq,
    Layout: NewLayout,
    Component: PurchaseQuotation,
    permission: PermissionEn.read
  },
  {
    path: purchasePath.pqVendorAdd,
    Layout: NewLayout,
    checkPath: purchasePath.pq,
    Component: AddPurchaseQuotationVendor,
    permission: PermissionEn.create
  },
  {
    path: `${purchasePath.pqDetail}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.pq,
    Component: PurchaseQuotationDetail,
    permission: PermissionEn.viewDetail
  },

  // PO - Đơn đặt mua hàng
  {
    path: purchasePath.po,
    Layout: NewLayout,
    Component: PurchaseOrder,
    permission: PermissionEn.read
  },
  {
    path: purchasePath.poAdd,
    Layout: NewLayout,
    checkPath: purchasePath.po,
    Component: AddPurchaseOrder,
    permission: PermissionEn.create
  },
  {
    path: `${purchasePath.poEdit}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.po,
    Component: AddPurchaseOrder,
    permission: PermissionEn.update
  },
  {
    path: `${purchasePath.poDetail}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.po,
    Component: AddPurchaseOrder,
    permission: PermissionEn.viewDetail
  },

  // GRPOR - Yêu cầu nhập kho
  {
    path: purchasePath.grpor,
    Layout: NewLayout,
    Component: GRPOR,
    permission: PermissionEn.read
  },
  {
    path: purchasePath.grporAdd,
    Layout: NewLayout,
    checkPath: purchasePath.grpor,
    Component: AddGRPOR,
    permission: PermissionEn.create
  },
  {
    path: `${purchasePath.grporEdit}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.grpor,
    Component: AddGRPOR,
    permission: PermissionEn.update
  },
  {
    path: `${purchasePath.grporDetail}/:id`,
    Layout: NewLayout,
    checkPath: purchasePath.grpor,
    Component: AddGRPOR,
    permission: PermissionEn.viewDetail
  },

  // Payment Method
  {
    path: purchasePath.paymentMethod,
    Layout: NewLayout,
    Component: PaymentMethod
  },
  {
    path: purchasePath.paymentMethodAdd,
    Layout: NewLayout,
    Component: PaymentMethodForm
  },
  {
    path: `${purchasePath.paymentMethodEdit}/:id`,
    Layout: NewLayout,
    Component: PaymentMethodForm
  },
  {
    path: `${purchasePath.paymentMethodDetail}/:id`,
    Layout: NewLayout,
    Component: PaymentMethodForm
  },
  // Shipping stage
  {
    path: purchasePath.shippingStage,
    Layout: NewLayout,
    Component: ShippingStage
  },
  {
    path: purchasePath.shippingStageAdd,
    Layout: NewLayout,
    Component: ShippingStageForm
  },
  {
    path: `${purchasePath.shippingStageEdit}/:id`,
    Layout: NewLayout,
    Component: ShippingStageForm
  },
  {
    path: `${purchasePath.shippingStageDetail}/:id`,
    Layout: NewLayout,
    Component: ShippingStageForm
  },

  // Receive plan
  {
    path: purchasePath.receivePlan,
    Layout: NewLayout,
    Component: ReceivePlan,
    permission: PermissionEn.read
  },
  // Order history
  {
    path: purchasePath.orderHistory,
    Layout: NewLayout,
    Component: HistoryOrder,
    permission: PermissionEn.read
  },
  // Debt group
  {
    path: purchasePath.debtGroup,
    Layout: NewLayout,
    Component: DebtGroup,
    permission: PermissionEn.read
  },
  // Debt
  {
    path: purchasePath.debt,
    Layout: NewLayout,
    Component: Debt,
    permission: PermissionEn.read
  },
  // SetOfDocument - Bộ chứng từ
  {
    path: purchasePath.setOfDocument,
    Layout: NewLayout,
    Component: SetOfDocument,
    permission: PermissionEn.read
  },
  // DocumentType - Loại chứng từ
  {
    path: purchasePath.documentType,
    Layout: NewLayout,
    Component: DocumentType,
    permission: PermissionEn.read
  },
  // RefKey
  {
    path: purchasePath.refKey,
    Layout: NewLayout,
    Component: RefKey,
    permission: PermissionEn.read
  }
];

export default purchaseRoutes;
