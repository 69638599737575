import { lazy } from 'react';
import installationPath from 'src/constants/urlPath/erp/installationPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const ProductionRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionRequest'));
const AddProductionRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/ProductionRequest/AddProductionRequest')
);

const installationRoutes: RouteType[] = [
  // Installation request - Lệnh lắp đặt
  {
    path: installationPath.installationRequest,
    Layout: NewLayout,
    Component: ProductionRequest
  },
  {
    path: installationPath.installationRequestAdd,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  {
    path: `${installationPath.installationRequestEdit}/:id`,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  {
    path: `${installationPath.installationRequestDetail}/:id`,
    Layout: NewLayout,
    Component: AddProductionRequest
  }
];

export default installationRoutes;
