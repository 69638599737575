import type { HierarchicalItem } from 'src/types/elements.type';
import type {
  IResource,
  ResourceAdd,
  ResourceAttachFile,
  ResourceShort,
  ResourceSort
} from 'src/types/master_data/resource.type';
import { http } from 'src/utils/http';

const controller = 'master-data/resources';

export const getResources = async (
  appInfoId?: number,
  isMenu?: boolean,
  isMobile?: boolean,
  sequenceStatus?: string
  // searchCondition?: string | undefined
) => {
  return await http.get<IResource[]>(`${controller}/get-by-app`, {
    params: { sequenceStatus, appInfoId, isMenu, isMobile }
  });
};
export const getResourcesByPath = async (path: string, isMenu?: boolean, searchCondition?: string | undefined) => {
  return await http.get<IResource[]>(`${controller}/get-by-path`, {
    params: { path, isMenu, searchCondition }
  });
};

export const getResourcesByParent = async (appInfoId?: number, parentId?: number, isMenu?: boolean) => {
  return await http.get<ResourceShort[]>(`${controller}/get-by-parent`, {
    params: {
      appInfoId,
      parentId,
      isMenu
    }
  });
};

export const getResource = async (id: number) => {
  return http.get<IResource>(`${controller}/${id}`);
};

export const getResourceByPath = async (path: string) => {
  return http.get<IResource>(`${controller}/path`, { params: { path } });
};

export const addResource = async (resource: ResourceAdd) => {
  return http.post<ResourceAdd>(controller, resource);
};

export const updateResource = async (id: number, resource: IResource) => {
  return await http.put(`${controller}/${id}`, resource);
};

export const sortResources = async (sorts: ResourceSort[]) => {
  return await http.put(`${controller}/sort`, sorts);
};

export const deleteResource = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getResourcesDropdown = async (appInfoId?: number, isMenu?: boolean) => {
  return http.get<HierarchicalItem[]>(`${controller}/dropdown`, {
    params: {
      appInfoId,
      isMenu
    }
  });
};

export const uploadResourceFile = async (formData: FormData) => {
  return await http.post<ResourceAttachFile[]>(`${controller}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getResourceFile = async (filePath: string) => {
  return await http.get(`${controller}/get-file`, {
    params: {
      fileUrl: filePath
    },
    responseType: 'blob'
  });
};
