import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const planningPath = {
  planning: '/erp/planning',

  // SR - Yêu cầu cấp hàng
  sr: '/erp/planning/sr',
  srDetail: '/erp/planning/sr' + CRUDPathSegmentEn.DETAIL,
  srAdd: '/erp/planning/sr' + CRUDPathSegmentEn.ADD,
  srEdit: '/erp/planning/sr' + CRUDPathSegmentEn.EDIT,

  // SR Summary - Yêu cầu cấp hàng (tổng hợp)
  srSummary: '/erp/planning/sr-summary',
  srSummaryDetail: '/erp/planning/sr-summary' + CRUDPathSegmentEn.DETAIL,

  // PR - Yêu cầu mua hàng
  pr: '/erp/planning/pr',
  prDetail: '/erp/planning/pr' + CRUDPathSegmentEn.DETAIL,
  prAdd: '/erp/planning/pr' + CRUDPathSegmentEn.ADD,
  prEdit: '/erp/planning/pr' + CRUDPathSegmentEn.EDIT,

  // PR Summary - Yêu cầu mua hàng (tổng hợp)
  prSummary: '/erp/planning/pr-summary',
  prSummaryDetail: '/erp/planning/pr-summary' + CRUDPathSegmentEn.DETAIL,

  // PQ - Báo giá mua hàng
  pq: '/erp/planning/pq',
  pqDetail: '/erp/planning/pq' + CRUDPathSegmentEn.DETAIL,

  // Sale order - Đơn bán hàng
  saleOrder: '/erp/planning/so',
  saleOrderDetail: '/erp/planning/so' + CRUDPathSegmentEn.DETAIL,

  // Production order - Yêu cầu sản xuất, chế tạo
  productionOrder: '/erp/planning/production-order',
  productionOrderDetail: '/erp/planning/production-order' + CRUDPathSegmentEn.DETAIL,

  // ITR - Phiếu yêu cầu chuyển kho
  itr: '/erp/planning/itr',
  itrDetail: '/erp/planning/itr' + CRUDPathSegmentEn.DETAIL,
  itrDetailCode: '/erp/planning/itr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  itrAdd: '/erp/planning/itr' + CRUDPathSegmentEn.ADD,
  itrEdit: '/erp/planning/itr' + CRUDPathSegmentEn.EDIT,

  // KPI
  kpi: '/erp/planning/kpi',

  // KPI Target
  target: '/erp/planning/kpi/target',
  targetDetail: '/erp/planning/kpi/target' + CRUDPathSegmentEn.DETAIL,
  targetAdd: '/erp/planning/kpi/target' + CRUDPathSegmentEn.ADD,
  targetEdit: '/erp/planning/kpi/target' + CRUDPathSegmentEn.EDIT,

  // Work assign
  workAssign: '/erp/planning/kpi/work-assign',
  workAssignDetail: '/erp/planning/kpi/work-assign' + CRUDPathSegmentEn.DETAIL,
  workAssignAdd: '/erp/planning/kpi/work-assign' + CRUDPathSegmentEn.ADD,
  workAssignEdit: '/erp/planning/kpi/work-assign' + CRUDPathSegmentEn.EDIT,

  // Work plan
  workPlan: '/erp/planning/kpi/work-plan',
  workPlanDetail: '/erp/planning/kpi/work-plan' + CRUDPathSegmentEn.DETAIL,
  workPlanAdd: '/erp/planning/kpi/work-plan' + CRUDPathSegmentEn.ADD,
  workPlanEdit: '/erp/planning/kpi/work-plan' + CRUDPathSegmentEn.EDIT,

  // Company budget - Ngân sách công ty
  companyBudget: '/erp/planning/company-budget',
  // Cost control - kiểm soát dự án
  costControl: '/erp/planning/cost-control',
  // Budget control - kiểm soát dự án
  budgetControl: '/erp/planning/budget-control',

  // MRP - Tính toán tồn kho
  mrp: '/erp/planning/mrp',
  mrpDetail: '/erp/planning/mrp' + CRUDPathSegmentEn.DETAIL,
  mrpResult: '/erp/planning/mrp/result',

  // MRP Report
  mrpReport: '/erp/planning/mrp-report',
  // INVENTORY - Tồn kho
  inventory: '/erp/planning/inventory',
  inventoryJournal: '/erp/planning/inventory/inventory-journal',

  // Safety Stock
  safetyStock: '/erp/planning/inventory/safety-stock',
  safetyStockDetail: '/erp/planning/inventory/safety-stock' + CRUDPathSegmentEn.DETAIL
};

export default planningPath;

// //#region planning
// planning: getPath('/erp/planning', ['sidebar.home', 'v2.erp.index', 'v2.erp.planning.index']),

// companyBudget: getPath('/erp/planning/company-budget'),
// costControl: getPath('/erp/planning/cost-control'),
// budgetControl: getPath('/erp/planning/budget-control'),
// //#region KPI
// kpi: getPath('/erp/planning/kpi', [
//   'sidebar.home',
//   'v2.erp.index',
//   'v2.erp.planning.index',
//   'v2.erp.planning.kpi.index'
// ]),

// // Target
// target: getPath('/erp/planning/kpi/target', ['sidebar.home', 'sidebar.planning.index', 'sidebar.planning.target']),
// targetAdd: getPath('/erp/planning/kpi/target' + CRUDPathSegmentEn.ADD, [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'sidebar.planning.target',
//   'action.add'
// ]),
// targetEdit: getPath('/erp/planning/kpi/target' + CRUDPathSegmentEn.EDIT, [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'sidebar.planning.target',
//   'action.' + CRUDPathSegmentEn.EDIT
// ]),
// targetDetail: getPath('/erp/planning/kpi/target/detail', [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'v2.planning.target',
//   'action.detail'
// ]),
// // Work assign
// workAssign: getPath('/erp/planning/kpi/work-assign', [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'sidebar.planning.workAssign'
// ]),
// workAssignAdd: getPath('/erp/planning/kpi/work-assign' + CRUDPathSegmentEn.ADD, [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'sidebar.planning.workAssign',
//   'action.add'
// ]),
// workAssignEdit: getPath('/erp/planning/kpi/work-assign' + CRUDPathSegmentEn.EDIT, [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'sidebar.planning.workAssign',
//   'action.' + CRUDPathSegmentEn.EDIT
// ]),
// workAssignDetail: getPath('/erp/planning/kpi/work-assign/detail', [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'v2.planning.workAssign',
//   'action.detail'
// ]),

// // Work Plan
// workPlan: getPath('/erp/planning/kpi/work-plan', [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'sidebar.planning.workPlan'
// ]),
// workPlanAdd: getPath('/erp/planning/kpi/work-plan' + CRUDPathSegmentEn.ADD, [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'sidebar.planning.workPlan',
//   'action.add'
// ]),
// workPlanEdit: getPath('/erp/planning/kpi/work-plan' + CRUDPathSegmentEn.EDIT, [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'v2.planning.workPlan',
//   'action.' + CRUDPathSegmentEn.EDIT
// ]),
// workPlanDetail: getPath('/erp/planning/kpi/work-plan/detail', [
//   'sidebar.home',
//   'sidebar.planning.index',
//   'v2.planning.workPlan',
//   'action.detail'
// ]),
// //#endregion KPI

// // PQ
// planningPQ: getPath('/erp/planning/pq', [
//   'sidebar.home',
//   'v2.erp.index',
//   'v2.erp.planning.index',
//   'v2.erp.planning.pq.index',
//   'v2.erp.planning.pq.' + CRUDPathSegmentEn.DETAIL
// ]),
// planningPQDetail: getPath('/erp/planning/pq' + CRUDPathSegmentEn.DETAIL, [
//   'sidebar.home',
//   'v2.projectPlanning.index',
//   'v2.projectPlanning.planningPQ',
//   'v2.projectPlanning.' + CRUDPathSegmentEn.DETAIL
// ]),

// // PR
// planningPR: getPath('/erp/planning/pr', [
//   'sidebar.home',
//   'v2.erp.index',
//   'v2.erp.planning.index',
//   'v2.erp.planning.pr.index'
// ]),
// planningPRDetail: getPath('/erp/planning/pr' + CRUDPathSegmentEn.DETAIL, [
//   'sidebar.home',
//   'v2.projectPlanning.index',
//   'v2.projectPlanning.planningPR',
//   'v2.projectPlanning.' + CRUDPathSegmentEn.DETAIL
// ]),
// planningPRDetailCode: getPath('/erp/planning/pr' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.pr',
//   'v2.purchase.' + CRUDPathSegmentEn.DETAIL
// ]),
// planningPRAdd: getPath('/erp/planning/pr' + CRUDPathSegmentEn.ADD, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.pr',
//   'action.add'
// ]),
// planningPREdit: getPath('/erp/planning/pr' + CRUDPathSegmentEn.EDIT, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.pr',
//   'action.' + CRUDPathSegmentEn.EDIT
// ]),

// // SR - SUPPLY REQUEST
// sr: getPath('/erp/planning/sr', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.sr']),
// srDetail: getPath('/erp/planning/sr' + CRUDPathSegmentEn.DETAIL, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.sr',
//   'v2.purchase.' + CRUDPathSegmentEn.DETAIL
// ]),
// srDetailCode: getPath('/erp/planning/sr' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.sr',
//   'v2.purchase.' + CRUDPathSegmentEn.DETAIL
// ]),
// srAdd: getPath('/erp/planning/sr' + CRUDPathSegmentEn.ADD, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.sr',
//   'action.add'
// ]),
// srEdit: getPath('/erp/planning/sr' + CRUDPathSegmentEn.EDIT, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.sr',
//   'action.' + CRUDPathSegmentEn.EDIT
// ]),

// // SR SUMMARY
// planningSrSummary: getPath('/erp/planning/sr-summary', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.sr']),
// planningSrSummaryDetail: getPath('/erp/planning/sr-summary' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.sr',
//   'v2.purchase.' + CRUDPathSegmentEn.DETAIL
// ]),
// // PR SUMMARY
// planningPrSummary: getPath('/erp/planning/pr-summary', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pr']),
// planningPrSummaryDetail: getPath('/erp/planning/pr-summary' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
//   'sidebar.home',
//   'v2.purchase.index',
//   'v2.purchase.pr',
//   'v2.purchase.' + CRUDPathSegmentEn.DETAIL
// ]),

// // Prod Order
// planningProductionOrder: getPath('/erp/planning/production-order', [
//   'sidebar.home',
//   'v2.projectPlanning.index',
//   'v2.projectPlanning.productionOrder'
// ]),

// // MRP
// mrp: getPath('/erp/planning/mrp', ['sidebar.home', 'v2.projectPlanning.index', 'v2.projectPlanning.mrp']),
// mrpReports: getPath('/erp/planning/mrp-report', [
//   'sidebar.home',
//   'v2.projectPlanning.index',
//   'v2.projectPlanning.mrp'
// ]),

// //#endregion planning
