import {
  DatabaseOutlined,
  FileTextOutlined,
  HomeOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import type { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoSm from 'src/assets/images/logo.png';
import logoLg from 'src/assets/images/logo.svg';
import { resourcePublic } from 'src/constants/resourcePublic';
import urlPath from 'src/constants/urlPath/urlPath';
import { AppContext } from 'src/contexts/app.context';
import useScreenSize from 'src/hooks/useScreenSize';
import { getLastItem } from 'src/utils/utils';
import productionPath from 'src/constants/urlPath/erp/productionPath';
import crmPath from 'src/constants/urlPath/erp/crmPath';

const { Sider } = Layout;

type MenuItem = {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  code?: string;
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  path?: string[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    path
  } as MenuItem;
}

function getItemV1(label: React.ReactNode, key: React.Key, path?: string[]): MenuItem {
  return getItem(label, key, undefined, undefined, path);
}

const items = (t: TFunction<'translation', undefined, 'translation'>): MenuItem[] => {
  return [
    getItem(t(String(getLastItem(urlPath.systemAdmin.path))), urlPath.systemAdmin.location, <HomeOutlined />),
    getItem(
      t('sidebar.administration.index'),
      t('sidebar.administration.index').toString(),
      <UsergroupDeleteOutlined />,
      [
        getItemV1(t(String(getLastItem(urlPath.template.path))), urlPath.template.location, urlPath.template.path),
        getItemV1(
          t(String(getLastItem(urlPath.approvalProcess.path))),
          urlPath.approvalProcess.location,
          urlPath.approvalProcess.path
        )
      ]
    ),
    getItem(t('sidebar.hr.index'), t('sidebar.hr.index').toString(), <TeamOutlined />, [
      getItem(t('sidebar.hr.biometric.index'), t('sidebar.hr.biometric.index').toString(), null, [
        getItemV1(
          t(String(getLastItem(urlPath.biometricLog.path))),
          urlPath.biometricLog.location,
          urlPath.biometricLog.path
        ),
        getItemV1(
          t(String(getLastItem(urlPath.biometricDevice.path))),
          urlPath.biometricDevice.location,
          urlPath.biometricDevice.path
        ),
        getItemV1(
          t(String(getLastItem(urlPath.biometricEmployeeMapping.path))),
          urlPath.biometricEmployeeMapping.location,
          urlPath.biometricEmployeeMapping.path
        )
      ]),
      getItemV1(
        t(String(getLastItem(urlPath.organizationChart.path))),
        urlPath.organizationChart.location,
        urlPath.organizationChart.path
      ),
      getItemV1(t(String(getLastItem(urlPath.branch.path))), urlPath.branch.location, urlPath.branch.path),
      getItemV1(t(String(getLastItem(urlPath.department.path))), urlPath.department.location, urlPath.department.path),
      getItemV1(t(String(getLastItem(urlPath.employee.path))), urlPath.employee.location, urlPath.employee.path),
      getItemV1(t(String(getLastItem(urlPath.position.path))), urlPath.position.location, urlPath.position.path),
      getItemV1(t(String(getLastItem(urlPath.board.path))), urlPath.board.location, urlPath.board.path),
      getItemV1(t(String(getLastItem(urlPath.level.path))), urlPath.level.location, urlPath.level.path),
      getItemV1(t(String(getLastItem(urlPath.tier.path))), urlPath.tier.location, urlPath.tier.path),
      getItemV1(t(String(getLastItem(urlPath.degree.path))), urlPath.degree.location, urlPath.degree.path),
      getItemV1(
        t(String(getLastItem(urlPath.certificate.path))),
        urlPath.certificate.location,
        urlPath.certificate.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.leaveRemainingYear.path))),
        urlPath.leaveRemainingYear.location,
        urlPath.leaveRemainingYear.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.employeeShift.path))),
        urlPath.employeeShift.location,
        urlPath.employeeShift.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.advancePaymentEmployee.path))),
        urlPath.advancePaymentEmployee.location,
        urlPath.advancePaymentEmployee.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.leaveTicketPackage.path))),
        urlPath.leaveTicketPackage.location,
        urlPath.leaveTicketPackage.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.employeeCompensation.path))),
        urlPath.employeeCompensation.location,
        urlPath.employeeCompensation.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.additionalWorkingTimePackage.path))),
        urlPath.additionalWorkingTimePackage.location,
        urlPath.additionalWorkingTimePackage.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.contractType.path))),
        urlPath.contractType.location,
        urlPath.contractType.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.hrTicketCategory.path))),
        urlPath.hrTicketCategory.location,
        urlPath.hrTicketCategory.path
      ),
      getItemV1(t(String(getLastItem(urlPath.holiday.path))), urlPath.holiday.location, urlPath.holiday.path),
      getItemV1(t(String(getLastItem(urlPath.shift.path))), urlPath.shift.location, urlPath.shift.path)
    ]),
    getItem(t('sidebar.master-data.index'), t('sidebar.master-data.index').toString(), <DatabaseOutlined />, [
      getItemV1(t(String(getLastItem(urlPath.asset.path))), urlPath.asset.location, urlPath.asset.path),
      getItemV1(t(String(getLastItem(urlPath.errorCode.path))), urlPath.errorCode.location, urlPath.errorCode.path),
      getItemV1(t(String(getLastItem(urlPath.partner.path))), urlPath.partner.location, urlPath.partner.path),
      getItemV1(t(String(getLastItem(urlPath.resource.path))), urlPath.resource.location, urlPath.resource.path),

      getItemV1(
        t(String(getLastItem(urlPath.jobCategory.path))),
        urlPath.jobCategory.location,
        urlPath.jobCategory.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.partnerType.path))),
        urlPath.partnerType.location,
        urlPath.partnerType.path
      ),
      getItemV1(t(String(getLastItem(urlPath.distance.path))), urlPath.distance.location, urlPath.distance.path),

      getItemV1(
        t(String(getLastItem(urlPath.fuelConsumption.path))),
        urlPath.fuelConsumption.location,
        urlPath.fuelConsumption.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.fuelUnitPrice.path))),
        urlPath.fuelUnitPrice.location,
        urlPath.fuelUnitPrice.path
      ),
      getItemV1(t(String(getLastItem(urlPath.subSystem.path))), urlPath.subSystem.location, urlPath.subSystem.path),
      getItemV1(
        t(String(getLastItem(urlPath.workDescription.path))),
        urlPath.workDescription.location,
        urlPath.workDescription.path
      ),

      getItemV1(
        t(String(getLastItem(urlPath.managerAssignment.path))),
        urlPath.managerAssignment.location,
        urlPath.managerAssignment.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.wareHouseGroup.path))),
        urlPath.wareHouseGroup.location,
        urlPath.wareHouseGroup.path
      ),
      getItemV1(t(String(getLastItem(urlPath.wareHouse.path))), urlPath.wareHouse.location, urlPath.wareHouse.path),
      getItemV1(
        t(String(getLastItem(urlPath.mobileVersion.path))),
        urlPath.mobileVersion.location,
        urlPath.mobileVersion.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.productionUnit.path))),
        urlPath.productionUnit.location,
        urlPath.productionUnit.path
      ),
      getItemV1(t(String(getLastItem(urlPath.groupUnit.path))), urlPath.groupUnit.location, urlPath.groupUnit.path),
      getItemV1(
        t(String(getLastItem(urlPath.unitOfMeasure.path))),
        urlPath.unitOfMeasure.location,
        urlPath.unitOfMeasure.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.fieldOfBusiness.path))),
        urlPath.fieldOfBusiness.location,
        urlPath.fieldOfBusiness.path
      ),
      getItemV1(t(String(getLastItem(urlPath.factory.path))), urlPath.factory.location, urlPath.factory.path),
      getItemV1(
        t(String(getLastItem(urlPath.productionLine.path))),
        urlPath.productionLine.location,
        urlPath.productionLine.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.binLocation.path))),
        urlPath.binLocation.location,
        urlPath.binLocation.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.technicianType.path))),
        urlPath.technicianType.location,
        urlPath.technicianType.path
      ),
      getItemV1(t(String(getLastItem(urlPath.technician.path))), urlPath.technician.location, urlPath.technician.path),
      getItemV1(t(String(getLastItem(urlPath.qrResource.path))), urlPath.qrResource.location, urlPath.qrResource.path),
      getItemV1(t(String(getLastItem(urlPath.dataTable.path))), urlPath.dataTable.location, urlPath.dataTable.path)
    ]),
    getItem(t('sidebar.ticket.index'), t('sidebar.ticket.index').toString(), <FileTextOutlined />, [
      getItemV1(t(String(getLastItem(urlPath.ticketType.path))), urlPath.ticketType.location, urlPath.ticketType.path),
      getItemV1(
        t(String(getLastItem(urlPath.ticketCategoryGroup.path))),
        urlPath.ticketCategoryGroup.location,
        urlPath.ticketCategoryGroup.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.ticketCategory.path))),
        urlPath.ticketCategory.location,
        urlPath.ticketCategory.path
      ),
      getItemV1(
        t(String(getLastItem(urlPath.userTicketCategory.path))),
        urlPath.userTicketCategory.location,
        urlPath.userTicketCategory.path
      ),
      getItemV1(t(String(getLastItem(urlPath.form.path))), urlPath.form.location, urlPath.form.path),
      getItemV1(
        t(String(getLastItem(urlPath.ticketFormMapping.path))),
        urlPath.ticketFormMapping.location,
        urlPath.ticketFormMapping.path
      ),
      getItemV1(t(String(getLastItem(urlPath.reason.path))), urlPath.reason.location, urlPath.reason.path)
    ]),

    getItem('CRM', 'CRM', <TeamOutlined />, [
      getItemV1('Sự kiện', crmPath.event, [crmPath.event]),
      getItemV1('Thông tin khách', crmPath.visitor, [crmPath.visitor])
    ]),
    getItem('Phân quyền', 'authorize', <SafetyCertificateOutlined />, [
      getItemV1(t(String(getLastItem(urlPath.permission.path))), urlPath.permission.location, urlPath.permission.path),
      getItemV1(t(String(getLastItem(urlPath.role.path))), urlPath.role.location, urlPath.role.path),
      getItemV1(
        t(String(getLastItem(urlPath.userResource.path))),
        urlPath.userResource.location,
        urlPath.userResource.path
      ),
      getItemV1('Phân quyền nghiệm thu thành phẩm', productionPath.acceptancePermission, [
        productionPath.acceptancePermission
      ]),
      getItemV1(
        t(String(getLastItem(urlPath.userJobCategory.path))),
        urlPath.userJobCategory.location,
        urlPath.userJobCategory.path
      )
    ])

    // getItem(
    //   t('sidebar.ticket-approval.index'),
    //   t('sidebar.ticket-approval.index').toString(),
    //   <FileProtectOutlined />,
    //   [
    //     getItemV1(
    //       t(String(getLastItem(urlPath.ticketApproval.path))),
    //       urlPath.ticketApproval.location,
    //       urlPath.ticketApproval.path
    //     ),
    //     getItemV1(
    //       t(String(getLastItem(urlPath.leaveTicketApproval.path))),
    //       urlPath.leaveTicketApproval.location,
    //       urlPath.leaveTicketApproval.path
    //     ),
    //     getItemV1(
    //       t(String(getLastItem(urlPath.overtimeTicketApproval.path))),
    //       urlPath.overtimeTicketApproval.location,
    //       urlPath.overtimeTicketApproval.path
    //     ),
    //     getItemV1(
    //       t(String(getLastItem(urlPath.transportationTicketApproval.path))),
    //       urlPath.transportationTicketApproval.location,
    //       urlPath.transportationTicketApproval.path
    //     ),
    //     getItemV1(
    //       t(String(getLastItem(urlPath.advancePaymentTicketApproval.path))),
    //       urlPath.advancePaymentTicketApproval.location,
    //       urlPath.advancePaymentTicketApproval.path
    //     ),
    //     getItemV1(
    //       t(String(getLastItem(urlPath.businessTripTicketApproval.path))),
    //       urlPath.businessTripTicketApproval.location,
    //       urlPath.businessTripTicketApproval.path
    //     ),
    //     getItemV1(
    //       t(String(getLastItem(urlPath.purchaseRequestTicketApproval.path))),
    //       urlPath.purchaseRequestTicketApproval.location,
    //       urlPath.purchaseRequestTicketApproval.path
    //     ),
    //     getItemV1(
    //       t(String(getLastItem(urlPath.handoverTicketApproval.path))),
    //       urlPath.handoverTicketApproval.location,
    //       urlPath.handoverTicketApproval.path
    //     )
    //   ]
    // )
  ];
};

// const splitStr = (str: string): string => {
//   return `/${str?.split('/')[2]}`;
// };

// const authenticateItems = (items: MenuItem[], paths: string[]) => {
//   return items.filter((item) => {
//     const child = item.children?.filter((itemV2) => {
//       if (paths?.includes(itemV2.key as string) || resourcePublic?.includes(itemV2.key as string)) {
//         return itemV2;
//       }

//       if (itemV2 && itemV2.children && itemV2.children?.length > 0) {
//         const childV2 = itemV2.children.filter(
//           (itemV3) => paths?.includes(itemV3.key as string) || resourcePublic?.includes(itemV3.key as string)
//         );
//         if (childV2.length > 0) {
//           itemV2.children = childV2;
//           return itemV2;
//         } else {
//           return null;
//         }
//       }
//       return null;
//     });

//     item.children = child;

//     if (item.children === undefined || item.children.length === 0) {
//       if (!paths?.includes(item.key as string) && !resourcePublic?.includes(item.key as string as string)) return null;
//     }

//     let subMenu: MenuItem[] | undefined = [];

//     item.children = item.children?.filter((item) => {
//       if (item.children && item.children.length > 0) {
//         subMenu?.push(item);
//         return null;
//       } else {
//         return item;
//       }
//     });

//     item.children?.unshift(...subMenu);

//     return item;
//   });
// };

const authenticateItems = (items: MenuItem[], paths: string[]): MenuItem[] => {
  const sortMenuItems = (menu: MenuItem[]): MenuItem[] => {
    return menu
      .map((item) => ({
        ...item,
        children: item.children ? sortMenuItems(item.children) : undefined // Đệ quy sắp xếp children nếu có
      }))
      .sort((a, b) => String(a.label).localeCompare(String(b.label))); // Sắp xếp theo chữ cái
  };

  const filteredItems = items.filter((item) => {
    const child = item.children?.filter((childItem) => {
      if (paths.includes(childItem.key as string) || resourcePublic.includes(childItem.key as string)) {
        return true;
      }

      if (childItem.children && childItem.children.length > 0) {
        const childV2 = childItem.children.filter(
          (childV3) => paths.includes(childV3.key as string) || resourcePublic.includes(childV3.key as string)
        );

        if (childV2.length > 0) {
          childItem.children = childV2;
          return true;
        }
      }
      return false;
    });

    item.children = child ? sortMenuItems(child) : undefined; // Sắp xếp children trước khi gán lại

    return (
      paths.includes(item.key as string) ||
      resourcePublic.includes(item.key as string) ||
      (item.children && item.children.length > 0)
    );
  });

  return sortMenuItems(filteredItems); // Sắp xếp danh sách chính trước khi trả về
};

interface Props {
  colorBgContainer: string;
}

export default function Sidebar({ colorBgContainer }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  const [openKey, setOpenKey] = useState<string[]>();

  const { resources } = useContext(AppContext);

  const { isSidebarCollapsed } = useContext(AppContext);

  const navigate = useNavigate();

  const isSmallScreen = useScreenSize() <= 1024;

  const paths = useMemo(() => {
    return resources?.map((x) => x.path);
  }, [resources]);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const rootSubmenuKeys: string[] = [
    urlPath.systemAdmin.location,
    t('sidebar.administration.index').toString(),
    t('sidebar.master-data.index').toString(),
    t('sidebar.hr.index').toString(),
    t('sidebar.ticket.index').toString(),
    t('sidebar.ticket-approval.index').toString()
  ];

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.reverse()[0];
    const check = rootSubmenuKeys?.includes(latestOpenKey);
    if (check) {
      setOpenKey([latestOpenKey]);
    } else {
      setOpenKey(keys);
    }
  };

  const backTop = () => {
    const element = document.querySelector('html');
    if (element) {
      element.scrollTop = 10;
    }
  };

  return (
    <Sider
      width={isSmallScreen ? 200 : '20%'}
      trigger={null}
      style={{
        boxShadow: '0 0 4px #036EB7',
        background: colorBgContainer,
        minHeight: '100vh'
      }}
      collapsible
      collapsed={isSmallScreen ? true : isSidebarCollapsed}
    >
      <div style={{ margin: 15, marginBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Link to='/'>
          <img src={isSmallScreen ? logoSm : isSidebarCollapsed ? logoSm : logoLg} height={60} alt='Martech' />
        </Link>
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        openKeys={openKey ? openKey : []}
        onOpenChange={onOpenChange}
        mode='inline'
        items={
          Array.isArray(paths)
            ? authenticateItems(items(t), paths)?.sort((a, b) => String(a?.label)?.localeCompare(String(b?.label)))
            : []
        }
        style={{ marginTop: 20, borderRight: 0, minHeight: 100 }}
        onClick={({ key }) => {
          backTop();
          navigate(key);
        }}
      />
    </Sider>
  );
}
