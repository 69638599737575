import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import planningPath from 'src/constants/urlPath/erp/planningPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const ProjectPlanning = lazy(() => import('src/pages/ProjectPlanning'));

const SaleOrder = lazy(() => import('src/pages/ERP/Sale/SaleOrder'));
const SaleOrderForm = lazy(() => import('src/pages/ERP/Sale/SaleOrder/AddSaleOrder'));

const SupplyRequest = lazy(() => import('src/pages/ERP/Planning/Sr'));
const AddSupplyRequest = lazy(() => import('src/pages/ERP/Planning/Sr/AddSupplyRequest'));

const PurchaseRequest = lazy(() => import('src/pages/ERP/Planning/Pr'));
const AddPurchaseRequest = lazy(() => import('src/pages/ERP/Planning/Pr/AddPurchaseRequest'));

// const PurchaseRequest = lazy(() => import('src/pages/Purchase/PurchaseRequest'));
// const AddPurchaseRequest = lazy(() => import('src/pages/Purchase/PurchaseRequest/AddPurchaseRequest'));

const PurchaseQuotation = lazy(() => import('src/pages/Purchase/PurchaseQuotation'));
const PurchaseQuotationDetail = lazy(() => import('src/pages/Purchase/PurchaseQuotation/PurchaseQuotationDetail'));

const ProductionOrder = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionOrder'));
const AddProductionOrder = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/ProductionOrder/AddProductionOrder')
);

const ITR = lazy(() => import('src/pages/Inventory/ITR'));
const ITRForm = lazy(() => import('src/pages/Inventory/ITR/ITRForm'));

const Target = lazy(() => import('src/pages/ERP/Planning/Kpi/Target'));
const AddTarget = lazy(() => import('src/pages/ERP/Planning/Kpi/AddTarget'));

const WorkAssign = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssign'));
const AddWorkAssign = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkAssign'));

const WorkPlan = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkPlan'));
const AddWorkPlan = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkPlan'));

// const SrSummary = lazy(() => import('src/pages/ERP/Planning/SrSummary'));
// const SrSummaryDetail = lazy(() => import('src/pages/ERP/Planning/SrSummary/SrSummaryDetail'));
// const PrSummary = lazy(() => import('src/pages/ERP/Planning/PrSummary'));
// const PrSummaryDetail = lazy(() => import('src/pages/ERP/Planning/PrSummary/PrSummaryDetail'));

const CompanyBudget = lazy(() => import('src/pages/ERP/Planning/CompanyBudget'));
const BudgetControl = lazy(() => import('src/pages/ERP/Planning/BudgetControl'));
const CostControl = lazy(() => import('src/pages/ERP/Planning/CostControl'));

const MRP = lazy(() => import('src/pages/ProjectPlanning/MRP'));
const MRPResult = lazy(() => import('src/pages/ProjectPlanning/MRP/MRPResult'));
const MRPDetail = lazy(() => import('src/pages/ProjectPlanning/MRP/MRPDetail'));

const SafetyStock = lazy(() => import('src/pages/ProjectPlanning/SafetyStock'));
const SafetyStockDetail = lazy(() => import('src/pages/ProjectPlanning/SafetyStock/SafetyStockDetail'));

const InventoryJournal = lazy(() => import('src/pages/ERP/Planning/InventoryJournal'));

const planningRoutes: RouteType[] = [
  {
    path: planningPath.planning,
    Layout: NewLayout,
    Component: ProjectPlanning
  },

  // Yêu cầu cấp hàng - SUPPLY REQUEST
  {
    path: planningPath.sr,
    Layout: NewLayout,
    Component: SupplyRequest,
    permission: PermissionEn.read
  },
  {
    path: planningPath.srAdd,
    Layout: NewLayout,
    checkPath: planningPath.sr,
    Component: AddSupplyRequest,
    permission: PermissionEn.create
  },
  {
    path: `${planningPath.srEdit}/:id`,
    Layout: NewLayout,
    checkPath: planningPath.sr,
    Component: AddSupplyRequest,
    permission: PermissionEn.update
  },
  {
    path: `${planningPath.srDetail}/:id`,
    Layout: NewLayout,
    checkPath: planningPath.sr,
    Component: AddSupplyRequest,
    permission: PermissionEn.viewDetail
  },
  // SR Summary - Yêu cầu cấp hàng (tổng hợp)
  // {
  //   path: planningPath.srSummary,
  //   Layout: NewLayout,
  //   Component: SrSummary,
  //   checkPath: planningPath.srSummary,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: `${planningPath.srSummaryDetail}/:id`,
  //   Layout: NewLayout,
  //   Component: SrSummaryDetail,
  //   checkPath: planningPath.srSummary,
  //   permission: PermissionEn.viewDetail
  // },

  // PR - Yêu cầu mua hàng
  {
    path: planningPath.pr,
    Layout: NewLayout,
    Component: PurchaseRequest,
    checkPath: planningPath.pr,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.prDetail}/:id`,
    Layout: NewLayout,
    Component: AddPurchaseRequest,
    checkPath: planningPath.pr,
    permission: PermissionEn.viewDetail
  },
  {
    path: planningPath.prAdd,
    Layout: NewLayout,
    Component: AddPurchaseRequest,
    checkPath: planningPath.pr,
    permission: PermissionEn.create
  },
  {
    path: `${planningPath.prEdit}/:id`,
    Layout: NewLayout,
    Component: AddPurchaseRequest,
    checkPath: planningPath.pr,
    permission: PermissionEn.update
  },

  // PR Summary - Yêu cầu mua hàng (tổng hợp)
  // {
  //   path: planningPath.prSummary,
  //   Layout: NewLayout,
  //   Component: PrSummary,
  //   checkPath: planningPath.prSummary,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: `${planningPath.prSummaryDetail}/:id`,
  //   Layout: NewLayout,
  //   Component: PrSummaryDetail,
  //   checkPath: planningPath.prSummary,
  //   permission: PermissionEn.viewDetail
  // },

  // PQ - Báo giá mua hàng
  {
    path: planningPath.pq,
    Layout: NewLayout,
    Component: PurchaseQuotation,
    checkPath: planningPath.pq,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.saleOrderDetail}/:id`,
    Layout: NewLayout,
    Component: PurchaseQuotationDetail,
    checkPath: planningPath.pq,
    permission: PermissionEn.viewDetail
  },

  // Sale order - Đơn bán hàng
  {
    path: planningPath.saleOrder,
    Layout: NewLayout,
    Component: SaleOrder,
    checkPath: planningPath.saleOrder,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.saleOrderDetail}/:id`,
    Layout: NewLayout,
    Component: SaleOrderForm,
    checkPath: planningPath.saleOrder,
    permission: PermissionEn.viewDetail
  },

  // Production order - Yêu cầu sản xuất, chế tạo
  {
    path: planningPath.productionOrder,
    Layout: NewLayout,
    Component: ProductionOrder,
    checkPath: planningPath.productionOrder,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.productionOrderDetail}/:id`,
    Layout: NewLayout,
    Component: AddProductionOrder,
    checkPath: planningPath.productionOrder,
    permission: PermissionEn.viewDetail
  },

  // ITR - Phiếu yêu cầu chuyển kho
  {
    path: planningPath.itr,
    Layout: NewLayout,
    Component: ITR,
    checkPath: planningPath.itr,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.itrDetail}/:id`,
    Layout: NewLayout,
    Component: ITRForm,
    checkPath: planningPath.itr,
    permission: PermissionEn.viewDetail
  },
  {
    path: planningPath.itrAdd,
    Layout: NewLayout,
    Component: ITRForm,
    checkPath: planningPath.itr,
    permission: PermissionEn.create
  },
  {
    path: `${planningPath.itrEdit}/:id`,
    Layout: NewLayout,
    Component: ITRForm,
    checkPath: planningPath.itr,
    permission: PermissionEn.update
  },

  // KPI Target
  {
    path: planningPath.target,
    Layout: NewLayout,
    Component: Target,
    checkPath: planningPath.target,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.targetDetail}/:id`,
    Layout: NewLayout,
    Component: AddTarget,
    checkPath: planningPath.target,
    permission: PermissionEn.viewDetail
  },
  {
    path: planningPath.targetAdd,
    Layout: NewLayout,
    Component: AddTarget,
    checkPath: planningPath.target,
    permission: PermissionEn.create
  },
  {
    path: `${planningPath.targetEdit}/:id`,
    Layout: NewLayout,
    Component: AddTarget,
    checkPath: planningPath.target,
    permission: PermissionEn.update
  },
  // Work assign
  {
    path: planningPath.workAssign,
    Layout: NewLayout,
    Component: WorkAssign,
    checkPath: planningPath.workAssign,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.workAssignDetail}/:id`,
    Layout: NewLayout,
    Component: AddWorkAssign,
    checkPath: planningPath.workAssign,
    permission: PermissionEn.viewDetail
  },
  {
    path: planningPath.workAssignAdd,
    Layout: NewLayout,
    Component: AddWorkAssign,
    checkPath: planningPath.workAssign,
    permission: PermissionEn.create
  },
  {
    path: `${planningPath.workAssignEdit}/:id`,
    Layout: NewLayout,
    Component: AddWorkAssign,
    checkPath: planningPath.workAssign,
    permission: PermissionEn.update
  },
  // Work plan
  {
    path: planningPath.workPlan,
    Layout: NewLayout,
    Component: WorkPlan,
    checkPath: planningPath.workPlan,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.workPlanDetail}/:id`,
    Layout: NewLayout,
    Component: AddWorkPlan,
    checkPath: planningPath.workPlan,
    permission: PermissionEn.viewDetail
  },
  {
    path: planningPath.workPlanAdd,
    Layout: NewLayout,
    Component: AddWorkPlan,
    checkPath: planningPath.workPlan,
    permission: PermissionEn.create
  },
  {
    path: `${planningPath.workPlanEdit}/:id`,
    Layout: NewLayout,
    Component: AddWorkPlan,
    checkPath: planningPath.workPlan,
    permission: PermissionEn.update
  },

  // Company budget - Ngân sách công ty
  {
    path: planningPath.companyBudget,
    Layout: NewLayout,
    Component: CompanyBudget,
    checkPath: planningPath.companyBudget,
    permission: PermissionEn.read
  },

  // Cost control - kiểm soát dự án
  {
    path: planningPath.budgetControl,
    Layout: NewLayout,
    Component: BudgetControl,
    checkPath: planningPath.budgetControl,
    permission: PermissionEn.read
  },

  // Budget control - kiểm soát dự án
  {
    path: planningPath.costControl,
    Layout: NewLayout,
    Component: CostControl,
    checkPath: planningPath.costControl,
    permission: PermissionEn.read
  },
  // MRP - Tính toán tồn kho
  {
    path: planningPath.mrp,
    Layout: NewLayout,
    Component: MRP,
    checkPath: planningPath.mrp,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.mrpDetail}/:id`,
    Layout: NewLayout,
    Component: MRPDetail,
    checkPath: planningPath.mrp,
    permission: PermissionEn.read
  },
  {
    path: planningPath.mrpResult,
    Layout: NewLayout,
    Component: MRPResult,
    checkPath: planningPath.mrp,
    permission: PermissionEn.read
  },
  // SafetyStock - tồn kho dự phòng
  {
    path: planningPath.safetyStock,
    Layout: NewLayout,
    Component: SafetyStock,
    checkPath: planningPath.safetyStock,
    permission: PermissionEn.read
  },
  {
    path: `${planningPath.safetyStockDetail}/:id`,
    Layout: NewLayout,
    Component: SafetyStockDetail,
    checkPath: planningPath.safetyStock,
    permission: PermissionEn.read
  },
  // Inventory Journal - Tồn kho hiện tại
  {
    path: planningPath.inventoryJournal,
    Layout: NewLayout,
    Component: InventoryJournal,
    checkPath: planningPath.inventoryJournal,
    permission: PermissionEn.read
  }
];

export default planningRoutes;
