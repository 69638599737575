import type {
  BusinessPartnerAdd,
  BusinessPartnerAssign,
  BusinessPartnerShort,
  IBusinessPartner
} from 'src/types/crm/businessPartner.type';
import type { IContactInfo } from 'src/types/crm/contactInfo.type';
import type { FlatItem } from 'src/types/elements.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'crm/business-partners';

export const getBusinessPartners = async (
  customerTypeId: number,
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IBusinessPartner>>(`${controller}/paging/${customerTypeId}`, {
    params: {
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getBusinessPartner = async (id: number) => {
  return http.get<IBusinessPartner>(`${controller}/${id}`);
};

export const addBusinessPartner = async (businessPartner: BusinessPartnerAdd) => {
  return http.post<IBusinessPartner>(controller, businessPartner);
};

export const updateBusinessPartner = async (id: number, businessPartner: BusinessPartnerAdd) => {
  return await http.put<IBusinessPartner[]>(`${controller}/${id}`, businessPartner);
};

export const deleteBusinessPartner = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getBusinessPartnerDropdown = async (customerTypeId?: number) => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: { customerTypeId }
  });
};

// TODO: backend chưa có api này
export const getBusinessPartnerShort = async () => {
  return await http.get<BusinessPartnerShort[]>(`${controller}/short`);
};
export const getContactsDropdown = async (businessPartnerId: number) => {
  return await http.get<IContactInfo[]>(`${controller}/contactInfos/${businessPartnerId}`);
};

export const assignLead = async (leadAssign: BusinessPartnerAssign) => {
  return await http.post<IBusinessPartner[]>(`${controller}/assign-lead`, leadAssign);
};

export const assignOpportunity = async (leadOpportunity: BusinessPartnerAssign) => {
  return await http.post<IBusinessPartner[]>(`${controller}/assign-opportunity`, leadOpportunity);
};
