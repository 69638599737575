import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const purchasePath = {
  purchase: '/erp/purchase',

  // PR - Yêu cầu mua hàng
  pr: '/erp/purchase/pr',
  prDetail: '/erp/purchase/pr' + CRUDPathSegmentEn.DETAIL,
  prAdd: '/erp/purchase/pr' + CRUDPathSegmentEn.ADD,
  prEdit: '/erp/purchase/pr' + CRUDPathSegmentEn.EDIT,

  // PQR - Yêu cầu báo giá mua hàng
  pqr: '/erp/purchase/pqr',
  pqrDetail: '/erp/purchase/pqr' + CRUDPathSegmentEn.DETAIL,
  pqrAdd: '/erp/purchase/pqr' + CRUDPathSegmentEn.ADD,
  pqrEdit: '/erp/purchase/pqr' + CRUDPathSegmentEn.EDIT,

  // PQ - Báo giá mua hàng
  pq: '/erp/purchase/pq',
  pqDetail: '/erp/purchase/pq' + CRUDPathSegmentEn.DETAIL,
  pqVendorAdd: '/erp/purchase/pq/vendor-add' + CRUDPathSegmentEn.ADD,

  // PO - Đơn đặt mua hàng
  po: '/erp/purchase/po',
  poStamp: '/erp/purchase/po-stamp',
  poDetail: '/erp/purchase/po' + CRUDPathSegmentEn.DETAIL,
  poAdd: '/erp/purchase/po' + CRUDPathSegmentEn.ADD,
  poEdit: '/erp/purchase/po' + CRUDPathSegmentEn.EDIT,

  // GRPOR - Yêu cầu nhập kho
  grpor: '/erp/purchase/grpor',
  grporDetail: '/erp/purchase/grpor' + CRUDPathSegmentEn.DETAIL,
  grporAdd: '/erp/purchase/grpor' + CRUDPathSegmentEn.ADD,
  grporEdit: '/erp/purchase/grpor' + CRUDPathSegmentEn.EDIT,

  // QC Result
  qrResult: '/erp/purchase/qc-result',
  qrResultDetail: '/erp/purchase/qc-result' + CRUDPathSegmentEn.DETAIL,
  qrResultAdd: '/erp/purchase/qc-result' + CRUDPathSegmentEn.ADD,
  qrResultEdit: '/erp/purchase/qc-result' + CRUDPathSegmentEn.EDIT,

  // Hạch toán
  purchaseAccounting: '/erp/purchase/accounting',
  purchaseAccountingDetail: '/erp/purchase/accounting' + CRUDPathSegmentEn.DETAIL,
  purchaseAccountingAdd: '/erp/purchase/accounting' + CRUDPathSegmentEn.ADD,
  purchaseAccountingEdit: '/erp/purchase/accounting' + CRUDPathSegmentEn.EDIT,

  // AP Invoice
  apInvoice: '/erp/purchase/apInvoice',
  apInvoiceDetail: '/erp/purchase/apInvoice' + CRUDPathSegmentEn.DETAIL,
  apInvoiceAdd: '/erp/purchase/apInvoice' + CRUDPathSegmentEn.ADD,
  apInvoiceEdit: '/erp/purchase/apInvoice' + CRUDPathSegmentEn.EDIT,

  // Supplier
  supplier: '/erp/purchase/supplier',
  supplierDetail: '/erp/purchase/supplier' + CRUDPathSegmentEn.DETAIL,
  supplierAdd: '/erp/purchase/supplier' + CRUDPathSegmentEn.ADD,
  supplierEdit: '/erp/purchase/supplier' + CRUDPathSegmentEn.EDIT,

  // Payment method - Hình thức thanh toán
  paymentMethod: '/erp/purchase/payment-method',
  paymentMethodDetail: '/erp/purchase/payment-method' + CRUDPathSegmentEn.DETAIL,
  paymentMethodAdd: '/erp/purchase/payment-method' + CRUDPathSegmentEn.ADD,
  paymentMethodEdit: '/erp/purchase/payment-method' + CRUDPathSegmentEn.EDIT,

  // Shipping Stage - Chặng vận chuyển
  shippingStage: '/erp/purchase/shipping-stage',
  shippingStageDetail: '/erp/purchase/shipping-stage' + CRUDPathSegmentEn.DETAIL,
  shippingStageAdd: '/erp/purchase/shipping-stage' + CRUDPathSegmentEn.ADD,
  shippingStageEdit: '/erp/purchase/shipping-stage' + CRUDPathSegmentEn.EDIT,

  // Receive plan
  receivePlan: '/erp/purchase/receive-plan',
  // Order history
  orderHistory: '/erp/purchase/orders-history',
  // Debt group
  debtGroup: '/erp/purchase/debt-group',
  // Debt
  debt: '/erp/purchase/debt',
  // SetOfDocument - Bộ chứng từ
  setOfDocument: '/erp/purchase/set-of-documents',
  // DocumentType - Loại chứng từ
  documentType: '/erp/purchase/document-type',
  // RefKey
  refKey: '/erp/purchase/ref-key'
};

export default purchasePath;
