import { lazy } from 'react';
import inventoryPath from 'src/constants/urlPath/erp/inventoryPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';
import { PermissionEn } from 'src/constants/permission.enum';

const Inventory = lazy(() => import('src/pages/Inventory'));
const ITR = lazy(() => import('src/pages/Inventory/ITR'));
const ITRForm = lazy(() => import('src/pages/Inventory/ITR/ITRForm'));

const IT = lazy(() => import('src/pages/Inventory/IT'));
const ITForm = lazy(() => import('src/pages/Inventory/IT/ITForm'));

const GRPOR = lazy(() => import('src/pages/Inventory/GRPOR'));
const AddGRPOR = lazy(() => import('src/pages/Inventory/GRPOR/AddGRPOR'));

const MRP = lazy(() => import('src/pages/ProjectPlanning/MRP'));
const MRPDetail = lazy(() => import('src/pages/ProjectPlanning/MRP/MRPResult'));

const inventoryRoutes: RouteType[] = [
  {
    path: inventoryPath.inventory,
    Layout: NewLayout,

    Component: Inventory
  },
  // ITR - Phiếu yêu cầu chuyển kho
  {
    path: inventoryPath.itr,
    Layout: NewLayout,
    Component: ITR,
    checkPath: inventoryPath.itr,
    permission: PermissionEn.read
  },
  {
    path: `${inventoryPath.itrDetail}/:id`,
    Layout: NewLayout,
    Component: ITRForm,
    checkPath: inventoryPath.itr,
    permission: PermissionEn.viewDetail
  },

  // IT - Phiếu chuyển kho
  {
    path: inventoryPath.it,
    Layout: NewLayout,
    Component: IT,
    checkPath: inventoryPath.it,
    permission: PermissionEn.read
  },
  {
    path: inventoryPath.itAdd,
    Layout: NewLayout,
    Component: ITForm,
    checkPath: inventoryPath.it,
    permission: PermissionEn.create
  },
  {
    path: `${inventoryPath.itEdit}/:id`,
    Layout: NewLayout,
    Component: ITForm,
    checkPath: inventoryPath.it,
    permission: PermissionEn.update
  },
  {
    path: `${inventoryPath.itDetail}/:id`,
    Layout: NewLayout,
    Component: ITForm,
    checkPath: inventoryPath.it,
    permission: PermissionEn.viewDetail
  },
  // Xem tồn kho
  {
    path: inventoryPath.inventoryMrp,
    Layout: NewLayout,
    Component: MRP
  },
  {
    path: `${inventoryPath.inventoryMrpDetail}/:id`,
    Layout: NewLayout,
    Component: MRPDetail
  },
  // GRPOR - Yêu cầu nhập kho
  {
    path: inventoryPath.grpor,
    Layout: NewLayout,
    Component: GRPOR
  },

  {
    path: `${inventoryPath.grporEdit}/:id`,
    Layout: NewLayout,
    Component: AddGRPOR
  },
  {
    path: `${inventoryPath.grporDetail}/:id`,
    Layout: NewLayout,
    Component: AddGRPOR
  }
];

export default inventoryRoutes;
