import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PermissionEn } from 'src/constants/permission.enum';
import accountingPath from 'src/constants/urlPath/erp/accountingPath';
import crmPath from 'src/constants/urlPath/erp/crmPath';
// import deliveryPath from 'src/constants/urlPath/erp/deliveryPath';
import productionPath from 'src/constants/urlPath/erp/productionPath';
import standardsQualityPath from 'src/constants/urlPath/erp/standardsQualityPath';
import Forbidden from 'src/pages/Error/Forbidden';
import InternalServer from 'src/pages/Error/InternalServer';
import NotFound from 'src/pages/Error/NotFound';
import accountingRoutes from 'src/routes/erp/accountingRoute';
import inventoryRoutes from 'src/routes/erp/inventoryRoute';
import productionRoutes from 'src/routes/erp/productionRoute';
import rndRoutes from 'src/routes/erp/rndRoute';
import type { IResourceFullAccessDetail } from 'src/types/master_data/userResource.type';
// import urlPathOld from '../constants/urlPath';
import { Spin } from 'antd';
import marketingRoutes from 'src/routes/crm/marketingRoute';
import crmMasterDataRoutes from 'src/routes/crm/masterDataRoute';
import preSaleRoutes from 'src/routes/crm/preSaleRoute';
import deliveryRoutes from 'src/routes/erp/deliveryRoute';
import itemMasterDataRoutes from 'src/routes/erp/itemMasterDataRoute';
import planningRoutes from 'src/routes/erp/planningRoute';
import saleRoutes from 'src/routes/erp/saleRoute';
import urlPath, { newUrlPath } from '../constants/urlPath/urlPath';
import NewLayout from '../layouts/NewFeatures/MainLayout';
import MainLayout from '../layouts/v1/MainLayout';
import MainLayoutV2 from '../layouts/v2/MainLayout';
import SigninOidc from '../pages/SigninOidc';
import SignoutCallbackOidc from '../pages/SignoutCallBackOidc';
import SignoutOidc from '../pages/SignoutOidc';
import { usePermissionNew } from './usePermission';
import installationRoutes from 'src/routes/erp/installationRoute';
import purchaseRoutes from 'src/routes/erp/purchaseRoute';

//#region Import components
const Home = lazy(() => import('src/pages/Home'));
const ApplicationMenu = lazy(() => import('src/pages/v2/Home/ApplicationMenu/ApplicationMenu'));

//#region Ticket
const TicketCategoryGroup = lazy(() => import('../pages/Ticket/TicketCategoryGroup'));
const TicketCategory = lazy(() => import('../pages/Ticket/TicketCategory'));
const AddTicketCategory = lazy(() => import('../pages/Ticket/TicketCategory/AddTicketCategory'));
const TicketCategoryDetail = lazy(() => import('../pages/Ticket/TicketCategoryDetail'));
const TicketType = lazy(() => import('../pages/Ticket/TicketType'));
const Form = lazy(() => import('src/pages/Ticket/Form/Form'));
const AddForm = lazy(() => import('src/pages/Ticket/Form/AddForm'));
const FormDetail = lazy(() => import('src/pages/Ticket/FormDetail'));
const Reason = lazy(() => import('src/pages/Ticket/Reason'));
const TicketFormMapping = lazy(() => import('src/pages/Ticket/TicketFormMapping'));
//#endregion

//#region Admininstration
const ApprovalProcess = lazy(() => import('src/pages/Administration/ApprovalProcess'));
const AddApprovalProcess = lazy(() => import('src/pages/Administration/AddApprovalProcess'));
const ApprovalProcessDetail = lazy(() => import('src/pages/Administration/ApprovalProcessDetail'));
const Template = lazy(() => import('src/pages/Administration/Template'));
const AddTemplate = lazy(() => import('src/pages/Administration/AddTemplate'));
//#endregion

//#region Master data
const Branch = lazy(() => import('src/pages/MasterData/Branch'));
const DataTable = lazy(() => import('src/pages/MasterData/DataTable'));
const AddDataTable = lazy(() => import('src/pages/MasterData/DataTable/AddDataTable'));
const Department = lazy(() => import('src/pages/MasterData/Department'));
const Employee = lazy(() => import('src/pages/MasterData/Employee'));
const Position = lazy(() => import('src/pages/MasterData/Position'));
const AddEmployee = lazy(() => import('src/pages/MasterData/AddEmployee'));
const EmployeeQrCode = lazy(() => import('src/pages/MasterData/EmployeeQrCode'));
const EmployeeDetail = lazy(() => import('src/pages/MasterData/EmployeeDetail'));
const Board = lazy(() => import('src/pages/MasterData/Board'));
const Level = lazy(() => import('src/pages/MasterData/Level'));
const GroupUnit = lazy(() => import('src/pages/MasterData/GroupUnit'));
const UnitOfMeasure = lazy(() => import('src/pages/MasterData/UnitOfMeasure'));
const Tier = lazy(() => import('src/pages/MasterData/Tier'));
const JobCategory = lazy(() => import('src/pages/MasterData/JobCategory'));
const AddJobCategory = lazy(() => import('src/pages/MasterData/AddJobCategory'));
const OrganizationChart = lazy(() => import('src/pages/MasterData/OrganizationChart'));
const Asset = lazy(() => import('src/pages/MasterData/Asset'));
const Degree = lazy(() => import('src/pages/MasterData/Degree'));
const FieldOfBusiness = lazy(() => import('src/pages/MasterData/FieldOfBusiness'));
const PartnerType = lazy(() => import('src/pages/MasterData/PartnerType'));
const Partner = lazy(() => import('src/pages/MasterData/Partner'));
const AddPartner = lazy(() => import('src/pages/MasterData/AddPartner'));
const ContractType = lazy(() => import('src/pages/MasterData/ContractType'));
const ApplicationInfo = lazy(() => import('src/pages/MasterData/ApplicationInfo'));
const Certificate = lazy(() => import('src/pages/MasterData/Certificate'));
const ErrorCode = lazy(() => import('src/pages/MasterData/ErrorCode'));
const HRTicketCategory = lazy(() => import('src/pages/MasterData/HRTicketCategory'));
const Resource = lazy(() => import('src/pages/MasterData/Resource'));
const Role = lazy(() => import('src/pages/MasterData/Role'));
const UserResource = lazy(() => import('src/pages/MasterData/UserResource'));
const AddRole = lazy(() => import('src/pages/MasterData/AddRole'));
const Permission = lazy(() => import('src/pages/MasterData/Permission'));
const Holiday = lazy(() => import('src/pages/MasterData/Holiday'));
const FuelConsumption = lazy(() => import('src/pages/MasterData/FuelConsumption'));
const FuelUnitPrice = lazy(() => import('src/pages/MasterData/FuelUnitPrice'));
const AdvancePaymentLimit = lazy(() => import('src/pages/Hr/AdvancePaymentLimit'));
const SubSystem = lazy(() => import('src/pages/MasterData/SubSystem'));
const WorkDescription = lazy(() => import('src/pages/MasterData/WorkDescription'));
const AddSubSystem = lazy(() => import('src/pages/MasterData/AddSubSystem'));
const AddApplicationInfo = lazy(() => import('src/pages/MasterData/AddApplicationInfo'));
const ApplicationInfoDetail = lazy(() => import('src/pages/MasterData/ApplicationInfoDetail'));
const Distance = lazy(() => import('src/pages/MasterData/Distance'));
const BiometricDevice = lazy(() => import('src/pages/Hr/BiometricDevice'));
const BiometricEmployeeMapping = lazy(() => import('src/pages/Hr/BiometricEmployeeMapping'));
const UserTicketCategory = lazy(() => import('src/pages/MasterData/UserTicketCategory'));
const UserJobCategory = lazy(() => import('src/pages/MasterData/UserJobCategory'));
const Shift = lazy(() => import('src/pages/MasterData/Shift'));
const ManagerAssignment = lazy(() => import('src/pages/MasterData/ManagerAssignment'));
const WareHouseGroup = lazy(() => import('src/pages/MasterData/WareHouseGroup'));
const WareHouse = lazy(() => import('src/pages/MasterData/WareHouse'));
const MobileVersion = lazy(() => import('src/pages/MasterData/MobileVersion'));
const ProductionUnit = lazy(() => import('src/pages/MasterData/ProductionUnit'));
const AddProductionUnit = lazy(() => import('src/pages/MasterData/AddProductionUnit'));
const QrResource = lazy(() => import('src/pages/MasterData/QrResource'));
const AddQrResource = lazy(() => import('src/pages/MasterData/AddQrResource'));
const AcceptancePermission = lazy(() => import('src/pages/MasterData/AcceptancePermission'));
const Cost = lazy(() => import('src/pages/MasterData/Cost'));
const EmployeeEvaluation = lazy(() => import('src/pages/Hr/EmployeeEvaluation'));
const Event = lazy(() => import('src/pages/CRM/Marketing/Event'));
const Visitor = lazy(() => import('src/pages/CRM/Marketing/Visitor'));
// factory
const Factory = lazy(() => import('src/pages/MasterData/Factory'));
const AddFactory = lazy(() => import('src/pages/MasterData/AddFactory'));
// productionLine
const ProductionLine = lazy(() => import('src/pages/MasterData/ProductionLine'));
const AddProductionLine = lazy(() => import('src/pages/MasterData/AddProductionLine'));
// binLocation
const BinLocation = lazy(() => import('src/pages/MasterData/BinLocation'));
const AddBinLocation = lazy(() => import('src/pages/MasterData/BinLocation/AddBinLocation'));
const BinLocationQrCode = lazy(() => import('src/pages/MasterData/BinLocation/BinLocationQrCode'));
// technicianType
const TechnicianType = lazy(() => import('src/pages/MasterData/TechnicianType'));
const AddTechnicianType = lazy(() => import('src/pages/MasterData/TechnicianType/AddTechnicianType'));
// technician
const Technician = lazy(() => import('src/pages/MasterData/Technician'));
const AddTechnician = lazy(() => import('src/pages/MasterData/Technician/AddTechnician'));

//#endregion

//#region HR
const EmployeeShift = lazy(() => import('src/pages/Hr/EmployeeShift'));
const AdvancePaymentEmployee = lazy(() => import('src/pages/Hr/AdvancePaymentEmployee'));
const LeaveTicketPackage = lazy(() => import('src/pages/Hr/LeaveTicketPackage'));
const AddLeaveTicketPackage = lazy(() => import('src/pages/Hr/AddLeaveTicketPackage'));
const LeaveTicketPackageDetail = lazy(() => import('src/pages/Hr/LeaveTicketPackageDetail'));
const AdditionalWorkingTimePackage = lazy(() => import('src/pages/Hr/AdditionalWorkingTimePackage'));
const AdditionalWorkingTimePackageDetail = lazy(() => import('src/pages/Hr/AdditionalWorkingTimePackageDetail'));
const EmployeeCompensation = lazy(() => import('src/pages/Hr/EmployeeCompensation'));
//#endregion

//#region Import components V2
const HomeV2 = lazy(() => import('src/pages/v2/Home'));
const HomeSub = lazy(() => import('src/pages/v2/HomeSub'));
const BiometricLogV2 = lazy(() => import('src/pages/v2/HrTicket/BiometricLog'));

// #region Ticket
// const TicketManagement = lazy(() => import('src/pages/v2/TicketManagement'));
const TicketsV2 = lazy(() => import('src/pages/v2/Ticket/Tickets'));
const TicketLayout = lazy(() => import('src/layouts/v2/TicketLayout'));
const SubLayout = lazy(() => import('src/layouts/v2/ExternalLayout'));
const AddTicketV2 = lazy(() => import('src/pages/v2/Ticket/AddTicket'));
const TicketDetailSolutionAddV2 = lazy(
  () => import('src/pages/v2/Ticket/TicketDetail/TicketDetailSolution/TicketDetailSolutionAdd')
);
//#endregion

// #region HR
const AdvancePaymentTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AdvancePaymentTicket'));
const AddAdvancePaymentTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddAdvancePaymentTicket'));

const TransportationTicketV2 = lazy(() => import('src/pages/v2/HrTicket/TransportationTicket'));
const AddTransportationTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddTransportationTicket'));

const LeaveTicketV2 = lazy(() => import('src/pages/v2/HrTicket/LeaveTicket'));
const LeaveTicketPackageV2 = lazy(() => import('src/pages/v2/HrTicket/LeaveTicketPackage'));
const AddLeaveTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddLeaveTicket'));
const AddLeaveTicketSub = lazy(() => import('src/pages/v2/Sub/AddLeaveTicket'));

const ExternalWorkerTicket = lazy(() => import('src/pages/v2/HrTicket/ExternalWorkerTicket'));
const AddExternalWorkerTicket = lazy(() => import('src/pages/v2/HrTicket/AddExternalWorkerTicket'));

const ExpenseTrackingRequestTicket = lazy(() => import('src/pages/v2/HrTicket/ExpenseTrackingRequestTicket'));
const AddExpenseTrackingRequestTicket = lazy(() => import('src/pages/v2/HrTicket/AddExpenseTrackingRequestTicket'));
const ExpenseTrackingRequestTicketHrConfirm = lazy(() => import('src/pages/v2/HrConfirm/ExpenseTrackingRequestTicket'));
const OvertimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/OvertimeTicket'));
const AddOvertimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddOvertimeTicket'));

const HandoverTicketV2 = lazy(() => import('src/pages/v2/OfficeTicket/HandoverTicket'));
const AddHandoverTicketV2 = lazy(() => import('src/pages/v2/OfficeTicket/AddHandoverTicket'));

const BusinessTripTicketV2 = lazy(() => import('src/pages/v2/HrTicket/BusinessTripTicket'));
const AddBusinessTripTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddBusinessTripTicket'));

const PurchaseRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/PurchaseRequestTicket'));
const AddPurchaseRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddPurchaseRequestTicket'));

const EquipmentSupplyRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/EquipmentSupplyRequestTicket'));
const AddEquipmentSupplyRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddEquipmentSupplyRequestTicket'));

const AdditionalWorkingTimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AdditionalWorkingTimeTicket'));
const AddAdditionalWorkingTimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddAdditionalWorkingTimeTicket'));

const AdditionalWorkingTimePackageV2 = lazy(() => import('src/pages/v2/HrTicket/AdditionalWorkingTimePackage'));

const LeaveTicketHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/LeaveTicket'));
const BusinessTripHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/BusinessTripTicket'));
const OvertimeHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/OvertimeTicket'));
const TransportationHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/TransportationTicket'));
//#endregion

//#region V2 USER GUIDE
const UserGuides = lazy(() => import('src/pages/v2/UserGuide/UserGuides'));
//#endregion V2 USER GUIDE

//#region V2 LEAVE REMAINING YEAR
const LeaveRemainingYear = lazy(() => import('src/pages/v2/HrTicket/LeaveRemainingYear'));
//#endregion V2 LEAVE REMAINING YEAR

//#region TOPIC
const TopicLayout = lazy(() => import('src/layouts/v2/TopicLayout'));
const TopicConversationBox = lazy(() => import('src/pages/v2/Topic/TopicConversationBox'));
//#endregion TOPIC

//#region REPORT
const ReportLayout = lazy(() => import('src/layouts/v2/ReportLayout'));
const ReportManagement = lazy(() => import('src/pages/v2/Report/ReportManagement'));
const ReportLeaveTicket = lazy(() => import('src/pages/v2/Report/LeaveTicket'));
const ReportOvertimeTicket = lazy(() => import('src/pages/v2/Report/OvertimeTicket'));
const PNL04 = lazy(() => import('src/pages/v2/Report/PNL04'));
const ReportBusinessTripTicket = lazy(() => import('src/pages/v2/Report/BusinessTripTicket'));
const ReportDailyInventoryJournal = lazy(() => import('src/pages/v2/Report/DailyInventoryJournal'));
//#endregion REPORT

// //#region ITEM MASTER DATA
// const ItemMasterDataHome = lazy(() => import('src/pages/ERP/ItemMasterData'));
// const ItemMasterData = lazy(() => import('src/pages/ERP/ItemMasterData/ItemMasterData'));
// const AddItemMasterData = lazy(() => import('src/pages/ERP/ItemMasterData/AddItemMasterData'));
// const ItemType = lazy(() => import('src/pages/ERP/ItemMasterData/ItemType'));
// const AddItemType = lazy(() => import('src/pages/ERP/ItemMasterData/AddItemType'));
// const ItemTypeDetail = lazy(() => import('src/pages/ERP/ItemMasterData/ItemTypeDetail'));
// const ItemGroup = lazy(() => import('src/pages/ERP/ItemMasterData/ItemGroup'));
// const AddItemGroup = lazy(() => import('src/pages/ERP/ItemMasterData/AddItemGroup'));
// const ItemGroupDetail = lazy(() => import('src/pages/ERP/ItemMasterData/ItemGroupDetail'));
// const Model = lazy(() => import('src/pages/ERP/ItemMasterData/Model'));
// const AddModel = lazy(() => import('src/pages/ERP/ItemMasterData/AddModel'));
// const ModelDetail = lazy(() => import('src/pages/ERP/ItemMasterData/ModelDetail'));
// const ItemSpec = lazy(() => import('src/pages/ERP/ItemMasterData/ItemSpec'));
// const AddItemSpec = lazy(() => import('src/pages/ERP/ItemMasterData/AddItemSpec'));
// const ItemSpecDetail = lazy(() => import('src/pages/ERP/ItemMasterData/ItemSpecDetail'));
// const AccountingItem = lazy(() => import('src/pages/ERP/ItemMasterData/AccountingItem'));
// const Specification = lazy(() => import('src/pages/ERP/ItemMasterData/Specification'));
// const ItemTechnicalSpecification = lazy(() => import('src/pages/ERP/ItemMasterData/ItemTechnicalSpecification'));
// const DimensionalStandard = lazy(() => import('src/pages/ERP/ItemMasterData/DimensionalStandard'));
// const AccountingAccountType = lazy(() => import('src/pages/MasterData/AccountingAccountType'));
// const AccountingObjectType = lazy(() => import('src/pages/MasterData/AccountingObjectType'));
// const GeneralAccountingAccount = lazy(() => import('src/pages/MasterData/GeneralAccountingAccount'));
// const Process = lazy(() => import('src/pages/ERP/ItemMasterData/Process'));
// const AddProcess = lazy(() => import('src/pages/ERP/ItemMasterData/AddProcess'));
// const ContractualUnitPrice = lazy(() => import('src/pages/ERP/ItemMasterData/ContractualUnitPrice'));
// // const ContractualUnitPriceForm = lazy(() => import('src/pages/ERP/ItemMasterData/ContractualUnitPriceForm'));
// //#endregion ITEM MASTER DATA

//#region CONTRACT EXECUTION
const ContractExecution = lazy(() => import('src/pages/ERP/ContractExecution'));

//#region PRODUCTION MANAGEMENT

const PackagingRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/PackagingRequest'));
const AddPackagingRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/PackagingRequest/AddPackagingRequest')
);

const DeliveryRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/DeliveryRequest'));
const AddDeliveryRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/DeliveryRequest/AddDeliveryRequest')
);

const DeliveryOrder = lazy(() => import('src/pages/ERP/ContractExecution/Production/DeliveryOrder'));
const AddDeliveryOrder = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/DeliveryOrder/AddDeliveryOrder')
);

const RawMatEst = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatEst'));
const AddRawMatEst = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatEst/AddRawMatEst'));

const BOM = lazy(() => import('src/pages/ERP/ContractExecution/Production/BOM'));
const AddBOM = lazy(() => import('src/pages/ERP/ContractExecution/Production/BOM/AddBOM/AddBOM'));

//#endregion PRODUCTION MANAGEMENT

//#region INSTALLATION REQUEST
const InstallationOrder = lazy(() => import('src/pages/ERP/ContractExecution/Installation/InstallationOrder'));
const InstallationRequest = lazy(() => import('src/pages/ERP/ContractExecution/Installation/InstallationRequest'));
const WarehouseTransferRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Installation/WarehouseTransferRequest')
);
const InstallationBudgetPlan = lazy(
  () => import('src/pages/ERP/ContractExecution/Installation/InstallationBudgetPlan')
);
const InstallationProcess = lazy(() => import('src/pages/ERP/ContractExecution/Installation/InstallationProcess'));
const QualityControl = lazy(() => import('src/pages/ERP/ContractExecution/Installation/QualityControl'));
//#endregion INSTALLATION REQUEST
//#endregion CONTRACT EXECUTION

//#region FINANCE
const Finance = lazy(() => import('src/pages/ERP/Finance'));
const CashFlowPlan = lazy(() => import('src/pages/ERP/Finance/CashFlowPlan'));
const FinanceReport = lazy(() => import('src/pages/ERP/Finance/FinanceReport'));
const PaymentPlan = lazy(() => import('src/pages/ERP/Finance/PaymentPlan'));
const CashFlowReport = lazy(() => import('src/pages/ERP/Finance/CashFlowReport'));
//#endregion FINANCE

//#region ACCOUNTING

const Accounting = lazy(() => import('src/pages/ERP/Accounting'));
const PaymentRequestTicket = lazy(() => import('src/pages/ERP/Accounting/PaymentRequestTicket'));

const GRPO = lazy(() => import('src/pages/Purchase/GRPO'));
const AddGRPO = lazy(() => import('src/pages/Purchase/GRPO/AddGRPO'));
const GRPODetail = lazy(() => import('src/pages/Purchase/GRPO/GRPODetail'));

//#endregion ACCOUNTING

//#region RECRUITMENT
const Candidate = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/Candidate'));
const EmploymentConfirmationNotice = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/EmploymentConfirmationNotice')
);
const InterviewInvitationLetter = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/InterviewInvitationLetter')
);
const JobOfferLetter = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/JobOfferLetter'));
const JobRequirement = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/JobRequirement'));
const RecruitmentActivityResults = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/RecruitmentActivityResults')
);
const RecruitmentPlan = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/RecruitmentPlan'));
const RecruitmentProcess = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/RecruitmentProcess'));
const ResultNotificationLetter = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/ResultNotificationLetter')
);
//#endregion RECRUITMENT

//#region TRAINING
const TrainingActivityResult = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingActivityResult')
);
const TrainingCost = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingCost'));
const TrainingDocumentation = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingDocumentation')
);
const TrainingInvitationLetter = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingInvitationLetter')
);
const TrainingParticipant = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingParticipant'));
const TrainingPlan = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingPlan'));
const TrainingProcess = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingProcess'));
const TrainingProgram = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingProgram'));
const TrainingRequirement = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingRequirement'));
const TrainingResult = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingResult'));
const TrainingResultNotification = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingResultNotification')
);
const TrainingSupplier = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingSupplier'));
//#endregion TRAINING

//#region Human Resource
const HumanResource = lazy(() => import('src/pages/ERP/HumanResource'));
const CnbJobDescription = lazy(() => import('src/pages/ERP/HumanResource/CnbManagement/JobDescription'));
//#endregion Human Resource

//#region  STANDARDS QUALITY
const StandardQuality = lazy(() => import('src/pages/ERP/StandardsQuality'));
const ProductionPlan = lazy(() => import('src/pages/ERP/StandardsQuality/ProductionPlan'));
const QcTemplate = lazy(() => import('src/pages/ERP/StandardsQuality/QcTemplate'));
const AddQcTemplate = lazy(() => import('src/pages/ERP/StandardsQuality/AddQcTemplate'));
const QcCheck = lazy(() => import('src/pages/ERP/StandardsQuality/QcCheck'));
const AddQcCheck = lazy(() => import('src/pages/ERP/StandardsQuality/AddQcCheck'));
const AddProductionPlan = lazy(() => import('src/pages/ERP/StandardsQuality/AddProductionPlan'));
const ProductionPlanDetail = lazy(() => import('src/pages/ERP/StandardsQuality/ProductionPlanDetail'));
const Standard = lazy(() => import('src/pages/ERP/StandardsQuality/Standard'));
const CheckList = lazy(() => import('src/pages/ERP/StandardsQuality/CheckList'));
const CertificationAssessmentPlan = lazy(() => import('src/pages/ERP/StandardsQuality/CertificationAssessmentPlan'));
const QualityInspectionRequirement = lazy(() => import('src/pages/ERP/StandardsQuality/QualityInspectionRequirement'));
const QualityControlProcess = lazy(() => import('src/pages/ERP/StandardsQuality/QualityControlProcess'));
const MaterialQualityManagement = lazy(() => import('src/pages/ERP/StandardsQuality/MaterialQualityManagement'));
const ProductionProcess = lazy(() => import('src/pages/Rnd/ProductionProcess'));

//#endregion STANDARDS QUALITYimport ProductionRequestDetail from './../pages/ContractExecution/Production/ProductionRequest/ProductionRequestDetail/index';

export interface RouteType {
  path: string;
  redirect?: string;
  Layout?: any;
  SubLayout?: any;
  Component?: any;
  permission?: PermissionEn;
  checkPath?: string;
  isUsePathV2?: boolean;
  hasSidebar?: boolean;
  hasSubSidebar?: boolean;
  hasSubHeader?: boolean;
  isHideDetailLayout?: boolean;
  isDisableFooter?: boolean;
}

const routesV1: RouteType[] = [
  {
    path: urlPath.systemAdmin.location,
    Layout: MainLayout,
    Component: Home
  },
  {
    path: urlPath.ticketType.location,
    Layout: MainLayout,
    Component: TicketType,
    checkPath: urlPath.ticketType.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.ticketCategoryGroup.location,
    Layout: MainLayout,
    Component: TicketCategoryGroup,
    checkPath: urlPath.ticketCategoryGroup.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.ticketCategory.location,
    Layout: MainLayout,
    Component: TicketCategory,
    checkPath: urlPath.ticketCategory.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.ticketCategoryAdd.location,
    Layout: MainLayout,
    Component: AddTicketCategory,
    checkPath: urlPath.ticketCategory.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.ticketCategoryEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddTicketCategory,
    checkPath: urlPath.ticketCategory.location,
    permission: PermissionEn.update
  },
  {
    path: `${urlPath.ticketCategoryDetail.location}/:id`,
    Layout: MainLayout,
    Component: TicketCategoryDetail,
    checkPath: urlPath.ticketCategory.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.form.location,
    Layout: MainLayout,
    Component: Form,
    checkPath: urlPath.form.location,
    permission: PermissionEn.read
  },
  {
    path: `${urlPath.formDetail.location}/:id`,
    Layout: MainLayout,
    Component: FormDetail,
    checkPath: urlPath.form.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.formAdd.location,
    Layout: MainLayout,
    Component: AddForm,
    checkPath: urlPath.form.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.formEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddForm,
    checkPath: urlPath.form.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.reason.location,
    Layout: MainLayout,
    Component: Reason,
    checkPath: urlPath.reason.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.ticketFormMapping.location,
    Layout: MainLayout,
    Component: TicketFormMapping,
    checkPath: urlPath.ticketFormMapping.location,
    permission: PermissionEn.read
  },

  {
    path: urlPath.biometricEmployeeMapping.location,
    Layout: MainLayout,
    Component: BiometricEmployeeMapping,
    checkPath: urlPath.biometricEmployeeMapping.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.leaveRemainingYear.location,
    Layout: MainLayout,
    Component: LeaveRemainingYear,
    checkPath: urlPath.leaveRemainingYear.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.leaveTicketPackage.location,
    Layout: MainLayout,
    Component: LeaveTicketPackage,
    checkPath: urlPath.leaveTicketPackage.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.leaveTicketPackageAdd.location,
    Layout: MainLayout,
    Component: AddLeaveTicketPackage,
    checkPath: urlPath.leaveTicketPackage.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.leaveTicketPackageDetail.location}/:id`,
    Layout: MainLayout,
    Component: LeaveTicketPackageDetail,
    checkPath: urlPath.leaveTicketPackage.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.additionalWorkingTimePackage.location,
    Layout: MainLayout,
    Component: AdditionalWorkingTimePackage,
    checkPath: urlPath.additionalWorkingTimePackage.location,
    permission: PermissionEn.read
  },
  {
    path: `${urlPath.additionalWorkingTimePackageDetail.location}/:id`,
    Layout: MainLayout,
    Component: AdditionalWorkingTimePackageDetail,
    checkPath: urlPath.additionalWorkingTimePackage.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.employeeCompensation.location,
    Layout: MainLayout,
    Component: EmployeeCompensation,
    checkPath: urlPath.employeeCompensation.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.employeeShift.location,
    Layout: MainLayout,
    Component: EmployeeShift,
    checkPath: urlPath.employeeShift.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.advancePaymentEmployee.location,
    Layout: MainLayout,
    Component: AdvancePaymentEmployee,
    checkPath: urlPath.advancePaymentEmployee.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.template.location,
    Layout: MainLayout,
    Component: Template,
    checkPath: urlPath.template.location,
    permission: PermissionEn.read
  },
  {
    path: `${urlPath.templateEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddTemplate,
    checkPath: urlPath.template.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.templateAdd.location,
    Layout: MainLayout,
    Component: AddTemplate,
    checkPath: urlPath.template.location,
    permission: PermissionEn.create
  },
  {
    path: urlPath.approvalProcess.location,
    Layout: MainLayout,
    Component: ApprovalProcess,
    checkPath: urlPath.approvalProcess.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.approvalProcessAdd.location,
    Layout: MainLayout,
    Component: AddApprovalProcess,
    checkPath: urlPath.approvalProcess.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.approvalProcessDetail.location}/:id`,
    Layout: MainLayout,
    Component: ApprovalProcessDetail,
    checkPath: urlPath.approvalProcess.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.approvalProcessEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddApprovalProcess,
    checkPath: urlPath.approvalProcess.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.branch.location,
    Layout: MainLayout,
    Component: Branch,
    checkPath: urlPath.branch.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.dataTable.location,
    Layout: MainLayout,
    Component: DataTable,
    checkPath: urlPath.dataTable.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.dataTableAdd.location,
    Layout: MainLayout,
    Component: AddDataTable,
    checkPath: urlPath.dataTable.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.dataTableEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddDataTable,
    checkPath: urlPath.dataTable.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.department.location,
    Layout: MainLayout,
    Component: Department,
    checkPath: urlPath.department.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.employee.location,
    Layout: MainLayout,
    Component: Employee,
    checkPath: urlPath.employee.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.employeeAdd.location,
    Layout: MainLayout,
    Component: AddEmployee,
    checkPath: urlPath.employee.location,
    permission: PermissionEn.create
  },
  {
    path: urlPath.employeeQR.location,
    Layout: MainLayout,
    Component: EmployeeQrCode,
    checkPath: urlPath.employee.location,
    permission: PermissionEn.get_qr
  },
  {
    path: `${urlPath.employeeEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddEmployee,
    checkPath: urlPath.employee.location,
    permission: PermissionEn.update
  },
  {
    path: `${urlPath.employeeDetail.location}/:id`,
    Layout: MainLayout,
    Component: EmployeeDetail,
    checkPath: urlPath.employee.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.position.location,
    Layout: MainLayout,
    Component: Position,
    checkPath: urlPath.position.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.board.location,
    Layout: MainLayout,
    Component: Board,
    checkPath: urlPath.board.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.level.location,
    Layout: MainLayout,
    Component: Level,
    checkPath: urlPath.level.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.groupUnit.location,
    Layout: MainLayout,
    Component: GroupUnit,
    checkPath: urlPath.groupUnit.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.unitOfMeasure.location,
    Layout: MainLayout,
    Component: UnitOfMeasure,
    checkPath: urlPath.unitOfMeasure.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.tier.location,
    Layout: MainLayout,
    Component: Tier,
    checkPath: urlPath.tier.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.jobCategory.location,
    Layout: MainLayout,
    Component: JobCategory,
    checkPath: urlPath.jobCategory.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.jobCategoryAdd.location,
    Layout: MainLayout,
    Component: AddJobCategory,
    checkPath: urlPath.jobCategory.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.jobCategoryEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddJobCategory,
    checkPath: urlPath.jobCategory.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.organizationChart.location,
    Layout: MainLayout,
    Component: OrganizationChart,
    checkPath: urlPath.organizationChart.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.partnerType.location,
    Layout: MainLayout,
    Component: PartnerType,
    checkPath: urlPath.partnerType.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.asset.location,
    Layout: MainLayout,
    Component: Asset,
    checkPath: urlPath.asset.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.degree.location,
    Layout: MainLayout,
    Component: Degree,
    checkPath: urlPath.degree.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.fieldOfBusiness.location,
    Layout: MainLayout,
    Component: FieldOfBusiness,
    checkPath: urlPath.fieldOfBusiness.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.partner.location,
    Layout: MainLayout,
    Component: Partner,
    checkPath: urlPath.partner.location,
    permission: PermissionEn.read
  },
  {
    path: `${urlPath.partnerEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddPartner,
    checkPath: urlPath.partner.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.partnerAdd.location,
    Layout: MainLayout,
    Component: AddPartner,
    checkPath: urlPath.partner.location,
    permission: PermissionEn.create
  },
  {
    path: urlPath.contractType.location,
    Layout: MainLayout,
    Component: ContractType,
    checkPath: urlPath.contractType.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.applicationInfo.location,
    Layout: MainLayout,
    Component: ApplicationInfo,
    checkPath: urlPath.applicationInfo.location,
    permission: PermissionEn.read
  },
  {
    path: `${urlPath.applicationInfoEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddApplicationInfo,
    checkPath: urlPath.applicationInfo.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.applicationInfoAdd.location,
    Layout: MainLayout,
    Component: AddApplicationInfo,
    checkPath: urlPath.applicationInfo.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.applicationInfoDetail.location}/:id`,
    Layout: MainLayout,
    Component: ApplicationInfoDetail,
    checkPath: urlPath.applicationInfo.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.certificate.location,
    Layout: MainLayout,
    Component: Certificate,
    checkPath: urlPath.certificate.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.errorCode.location,
    Layout: MainLayout,
    Component: ErrorCode,
    checkPath: urlPath.errorCode.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.hrTicketCategory.location,
    Layout: MainLayout,
    Component: HRTicketCategory,
    checkPath: urlPath.hrTicketCategory.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.resource.location,
    Layout: MainLayout,
    Component: Resource,
    checkPath: urlPath.resource.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.role.location,
    Layout: MainLayout,
    Component: Role,
    checkPath: urlPath.role.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.roleAdd.location,
    Layout: MainLayout,
    Component: AddRole,
    checkPath: urlPath.role.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.roleEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddRole,
    checkPath: urlPath.role.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.userResource.location,
    Layout: MainLayout,
    Component: UserResource,
    checkPath: urlPath.userResource.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.permission.location,
    Layout: MainLayout,
    Component: Permission,
    checkPath: urlPath.permission.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.biometricDevice.location,
    Layout: MainLayout,
    Component: BiometricDevice,
    checkPath: urlPath.biometricDevice.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.distance.location,
    Layout: MainLayout,
    Component: Distance,
    checkPath: urlPath.distance.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.holiday.location,
    Layout: MainLayout,
    Component: Holiday,
    checkPath: urlPath.holiday.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.fuelConsumption.location,
    Layout: MainLayout,
    Component: FuelConsumption,
    checkPath: urlPath.fuelConsumption.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.fuelUnitPrice.location,
    Layout: MainLayout,
    Component: FuelUnitPrice,
    checkPath: urlPath.fuelUnitPrice.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.advancePaymentLimit.location,
    Layout: MainLayout,
    Component: AdvancePaymentLimit,
    checkPath: urlPath.advancePaymentLimit.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.employeeEvaluation.location,
    Layout: MainLayout,
    Component: EmployeeEvaluation,
    checkPath: urlPath.employeeEvaluation.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.subSystem.location,
    Layout: MainLayout,
    Component: SubSystem,
    checkPath: urlPath.subSystem.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.subSystemAdd.location,
    Layout: MainLayout,
    Component: AddSubSystem,
    checkPath: urlPath.subSystem.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.subSystemEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddSubSystem,
    checkPath: urlPath.subSystem.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.workDescription.location,
    Layout: MainLayout,
    Component: WorkDescription,
    checkPath: urlPath.workDescription.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.userTicketCategory.location,
    Layout: MainLayout,
    Component: UserTicketCategory,
    checkPath: urlPath.userTicketCategory.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.userJobCategory.location,
    Layout: MainLayout,
    Component: UserJobCategory,
    checkPath: urlPath.userJobCategory.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.shift.location,
    Layout: MainLayout,
    Component: Shift,
    checkPath: urlPath.shift.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.managerAssignment.location,
    Layout: MainLayout,
    Component: ManagerAssignment,
    checkPath: urlPath.managerAssignment.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.wareHouseGroup.location,
    Layout: MainLayout,
    Component: WareHouseGroup,
    checkPath: urlPath.wareHouseGroup.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.wareHouse.location,
    Layout: MainLayout,
    Component: WareHouse,
    checkPath: urlPath.wareHouse.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.mobileVersion.location,
    Layout: MainLayout,
    Component: MobileVersion,
    checkPath: urlPath.mobileVersion.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.productionUnit.location,
    Layout: MainLayout,
    Component: ProductionUnit,
    checkPath: urlPath.productionUnit.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.productionUnitAdd.location,
    Layout: MainLayout,
    Component: AddProductionUnit,
    checkPath: urlPath.productionUnit.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.productionUnitEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddProductionUnit,
    checkPath: urlPath.productionUnit.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.qrResource.location,
    Layout: MainLayout,
    Component: QrResource,
    checkPath: urlPath.qrResource.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.qrResourceAdd.location,
    Layout: MainLayout,
    Component: AddQrResource,
    checkPath: urlPath.qrResource.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.qrResourceEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddQrResource,
    checkPath: urlPath.qrResource.location,
    permission: PermissionEn.update
  },
  // PRODUCTION-LINE
  {
    path: urlPath.productionLine.location,
    Layout: MainLayout,
    Component: ProductionLine,
    checkPath: urlPath.productionLine.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.productionLineAdd.location,
    Layout: MainLayout,
    Component: AddProductionLine,
    checkPath: urlPath.productionLine.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.productionLineEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddProductionLine,
    checkPath: urlPath.productionLine.location,
    permission: PermissionEn.update
  },
  // FACTORY
  {
    path: urlPath.factory.location,
    Layout: MainLayout,
    Component: Factory,
    checkPath: urlPath.factory.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.factoryAdd.location,
    Layout: MainLayout,
    Component: AddFactory,
    checkPath: urlPath.factory.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.factoryEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddFactory,
    checkPath: urlPath.factory.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.cost.location,
    Layout: MainLayout,
    Component: Cost,
    checkPath: urlPath.cost.location,
    permission: PermissionEn.read
  },
  {
    path: crmPath.event,
    Layout: MainLayout,
    Component: Event,
    checkPath: crmPath.event,
    permission: PermissionEn.read
  },
  {
    path: crmPath.visitor,
    Layout: MainLayout,
    Component: Visitor,
    checkPath: crmPath.visitor,
    permission: PermissionEn.read
  },
  {
    path: productionPath.acceptancePermission,
    Layout: MainLayout,
    Component: AcceptancePermission,
    checkPath: productionPath.acceptancePermission,
    permission: PermissionEn.read
  },
  // BIN-LOCATION
  {
    path: urlPath.binLocation.location,
    Layout: MainLayout,
    Component: BinLocation,
    checkPath: urlPath.binLocation.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.binLocationAdd.location,
    Layout: MainLayout,
    Component: AddBinLocation,
    checkPath: urlPath.binLocation.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.binLocationEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddBinLocation,
    checkPath: urlPath.binLocation.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.binLocationQR.location,
    Layout: MainLayout,
    Component: BinLocationQrCode,
    checkPath: urlPath.binLocation.location,
    permission: PermissionEn.get_qr
  },
  // TECHNICIAN-TYPE
  {
    path: urlPath.technicianType.location,
    Layout: MainLayout,
    Component: TechnicianType,
    checkPath: urlPath.technicianType.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.technicianTypeAdd.location,
    Layout: MainLayout,
    Component: AddTechnicianType,
    checkPath: urlPath.technicianType.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.technicianTypeEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddTechnicianType,
    checkPath: urlPath.technicianType.location,
    permission: PermissionEn.update
  },
  // TECHNICIAN
  {
    path: urlPath.technician.location,
    Layout: MainLayout,
    Component: Technician,
    checkPath: urlPath.technician.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.technicianAdd.location,
    Layout: MainLayout,
    Component: AddTechnician,
    checkPath: urlPath.technicianType.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.technicianEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddTechnician,
    checkPath: urlPath.technicianType.location,
    permission: PermissionEn.update
  }
];

const routesV2: RouteType[] = [
  {
    path: urlPath.home.location,
    Layout: MainLayoutV2,
    Component: HomeV2
  },
  {
    path: newUrlPath.applicationMenuDrawer,
    Layout: MainLayoutV2,
    Component: ApplicationMenu
  },

  //#region TICKET RAISING
  // {
  //   path: urlPath.ticketRaising.location,
  //   Layout: MainLayoutV2,
  //   SubLayout: TicketLayout,
  //   Component: TicketManagement,
  //   hasSidebar: false,
  //   hasSubHeader: false
  // },
  {
    path: urlPath.hrTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.ticketRaising.location,

    hasSubHeader: false
  },
  {
    path: urlPath.ticket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.myTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.myTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.approval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.approval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.accept.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.accept.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.solution.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.solution.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.solutionAdd.location}`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketDetailSolutionAddV2,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.solutionEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketDetailSolutionAddV2,
    isHideDetailLayout: true
  },
  {
    path: urlPath.handle.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.handle.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.ticketAdd.location}`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.ticketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.advancePaymentTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myAdvancePaymentTicket.location,
    hasSidebar: true
  },
  {
    path: urlPath.myAdvancePaymentTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
    // checkPath: urlPath.advancePaymentTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.advancePaymentTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
    // checkPath: urlPath.advancePaymentTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myAdvancePaymentTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
    // checkPath: urlPath.advancePaymentTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.advancePaymentTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
    // checkPath: urlPath.advancePaymentTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.advancePaymentTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdvancePaymentTicketV2,

    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.advancePaymentTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.advancePaymentTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdvancePaymentTicketV2,

    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.advancePaymentTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.transportationTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myTransportationTicket.location,
    hasSidebar: true
  },
  {
    path: urlPath.myTransportationTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.transportationTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: urlPath.transportationTicketConfirm.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicketConfirm.location
    // permission: PermissionEn.confirm
  },
  {
    path: urlPath.transportationTicketHandle.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicketHandle.location
    // permission: PermissionEn.confirm
  },
  {
    path: `${urlPath.myTransportationTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.transportationTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.transportationTicketConfirm.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicketConfirm.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.transportationTicketHandle.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicketHandle.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.transportationTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTransportationTicketV2,

    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.transportationTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.transportationTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTransportationTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.transportationTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.leaveTicket.location,
    Layout: MainLayoutV2,
    redirect: urlPath.myLeaveTicket.location,
    hasSubHeader: false,
    isDisableFooter: true
  },
  {
    path: urlPath.myLeaveTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.leaveTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
    // checkPath: urlPath.leaveTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: urlPath.leaveTicketPackageApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketPackageV2,
    hasSidebar: true
    // checkPath: urlPath.leaveTicketPackageApproval.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myLeaveTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.leaveTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
    // checkPath: urlPath.leaveTicketApproval.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.leaveTicketPackageApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketPackageV2,
    hasSidebar: true
    // checkPath: urlPath.leaveTicketPackageApproval.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.leaveTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddLeaveTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.leaveTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddLeaveTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },

  {
    path: urlPath.externalWorkerTicket.location,
    Layout: MainLayoutV2,
    redirect: urlPath.myExternalWorkerTicket.location,
    hasSubHeader: false,
    isDisableFooter: true
  },
  {
    path: urlPath.myExternalWorkerTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
    // checkPath: urlPath.externalWorkerTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.externalWorkerTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
    // checkPath: urlPath.externalWorkerTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myExternalWorkerTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
    // checkPath: urlPath.externalWorkerTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.externalWorkerTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
    // checkPath: urlPath.externalWorkerTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.externalWorkerTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExternalWorkerTicket,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.externalWorkerTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.externalWorkerTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExternalWorkerTicket,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.externalWorkerTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.expenseTrackingRequestTicket.location,
    Layout: MainLayoutV2,
    redirect: urlPath.myExpenseTrackingRequestTicket.location,
    hasSubHeader: false,
    isDisableFooter: true
  },
  {
    path: urlPath.myExpenseTrackingRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
  },
  {
    path: urlPath.expenseTrackingRequestTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
    // checkPath: urlPath.expenseTrackingRequestTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myExpenseTrackingRequestTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
    // checkPath: urlPath.expenseTrackingRequestTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.expenseTrackingRequestTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
    // checkPath: urlPath.expenseTrackingRequestTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.expenseTrackingRequestTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExpenseTrackingRequestTicket,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.expenseTrackingRequestTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.expenseTrackingRequestTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExpenseTrackingRequestTicket,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.expenseTrackingRequestTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.overtimeTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myOvertimeTicket.location,
    hasSidebar: true
  },
  {
    path: urlPath.myOvertimeTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.overtimeTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: urlPath.overtimeTicketSupervisorConfirm.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: `${urlPath.myOvertimeTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.overtimeTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.overtimeTicketSupervisorConfirm.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.overtimeTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddOvertimeTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.overtimeTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddOvertimeTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.overtimeTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.businessTripTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myBusinessTripTicket.location,
    hasSidebar: true
  },
  {
    path: urlPath.myBusinessTripTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
    // checkPath: urlPath.businessTripTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.businessTripTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
    // checkPath: urlPath.businessTripTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myBusinessTripTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
    // checkPath: urlPath.businessTripTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.businessTripTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
    // checkPath: urlPath.businessTripTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.businessTripTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddBusinessTripTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.businessTripTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.businessTripTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddBusinessTripTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.businessTripTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.purchaseRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myPurchaseRequestTicket.location,
    hasSidebar: true
  },
  {
    path: urlPath.myPurchaseRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.purchaseRequestTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.purchaseRequestTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.purchaseRequestTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myPurchaseRequestTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.purchaseRequestTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.purchaseRequestTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.purchaseRequestTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.purchaseRequestTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddPurchaseRequestTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.purchaseRequestTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.purchaseRequestTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddPurchaseRequestTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.purchaseRequestTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.equipmentSupplyRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myEquipmentSupplyRequestTicket.location,
    hasSidebar: true
  },
  {
    path: urlPath.myEquipmentSupplyRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.equipmentSupplyRequestTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.equipmentSupplyRequestTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.equipmentSupplyRequestTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myEquipmentSupplyRequestTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.equipmentSupplyRequestTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.equipmentSupplyRequestTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
    // checkPath: urlPath.equipmentSupplyRequestTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.equipmentSupplyRequestTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddEquipmentSupplyRequestTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.equipmentSupplyRequestTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.equipmentSupplyRequestTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddEquipmentSupplyRequestTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.equipmentSupplyRequestTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.additionalWorkingTimeTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myAdditionalWorkingTimeTicket.location,
    hasSidebar: true
  },
  {
    path: urlPath.myAdditionalWorkingTimeTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.additionalWorkingTimeTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: urlPath.additionalWorkingTimeTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimePackageV2,
    hasSidebar: true
    // checkPath: urlPath.additionalWorkingTimeTicket.location,
    // permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myAdditionalWorkingTimeTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimeTicketV2,
    hasSidebar: true
    // checkPath: urlPath.additionalWorkingTimeTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.additionalWorkingTimeTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimePackageV2,
    hasSidebar: true
    // checkPath: urlPath.additionalWorkingTimeTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.additionalWorkingTimeTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdditionalWorkingTimeTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.additionalWorkingTimeTicket.location,
    // permission: PermissionEn.create
  },
  {
    path: `${urlPath.additionalWorkingTimeTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdditionalWorkingTimeTicketV2,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
    // checkPath: urlPath.additionalWorkingTimeTicket.location,
    // permission: PermissionEn.update
  },
  {
    path: urlPath.hrConfirm.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.ticketRaising.location,
    hasSubHeader: false
  },
  {
    path: urlPath.hrConfirmLeaveTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmLeaveTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: `${urlPath.hrConfirmLeaveTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmLeaveTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicketHrConfirm,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: `${urlPath.hrConfirmExpenseTrackingRequestTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicketHrConfirm,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
    // permission: PermissionEn.viewDetail
  },

  {
    path: urlPath.hrConfirmBusinessTripTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmBusinessTripTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: `${urlPath.hrConfirmBusinessTripTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmBusinessTripTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.hrConfirmOvertimeTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmOvertimeTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: `${urlPath.hrConfirmOvertimeTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmOvertimeTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.hrConfirmTransportationTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmTransportationTicket.location,
    // permission: PermissionEn.read
  },
  {
    path: `${urlPath.hrConfirmTransportationTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationHrConfirmV2,
    hasSidebar: true
    // checkPath: urlPath.hrConfirmTransportationTicket.location,
    // permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.biometricLog.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BiometricLogV2,
    hasSidebar: false
  },
  {
    path: urlPath.report.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportManagement,
    checkPath: urlPath.report.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.reportLeaveTicket.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportLeaveTicket,
    checkPath: urlPath.reportLeaveTicket.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.reportOvertimeTicket.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportOvertimeTicket,
    checkPath: urlPath.reportOvertimeTicket.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.reportPNL04.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: PNL04,
    checkPath: urlPath.reportPNL04.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.reportBusinessTripTicket.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportBusinessTripTicket,
    checkPath: urlPath.reportBusinessTripTicket.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.reportDailyInventoryJournal.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportDailyInventoryJournal,
    checkPath: urlPath.reportDailyInventoryJournal.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.userGuide.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: UserGuides,
    hasSidebar: false
  },
  {
    path: urlPath.topic.location,
    Layout: MainLayoutV2,
    SubLayout: TopicLayout,
    Component: TopicConversationBox
  },
  {
    path: `${urlPath.topic.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TopicLayout,
    Component: TopicConversationBox
  },
  {
    path: urlPath.homeSub.location,
    Layout: MainLayoutV2,
    Component: HomeSub
  },
  {
    path: urlPath.qrScanner.location,
    Layout: MainLayoutV2,
    Component: HomeSub
  },
  {
    path: urlPath.biometricLogSub.location,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: BiometricLogV2,
    hasSidebar: false
  },
  {
    path: urlPath.myLeaveTicketSub.location,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: LeaveTicketV2,

    hasSubSidebar: false
  },
  {
    path: `${urlPath.myLeaveTicketSub.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: LeaveTicketV2,
    hasSidebar: false
  },
  {
    path: urlPath.leaveTicketAddSub.location,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: AddLeaveTicketSub,

    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.leaveTicketEditSub.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: AddLeaveTicketSub,

    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myHandoverTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true,
    checkPath: urlPath.handoverTicket.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.handoverTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true,
    checkPath: urlPath.handoverTicket.location,
    permission: PermissionEn.approve
  },
  {
    path: `${urlPath.myHandoverTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true,
    checkPath: urlPath.handoverTicket.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${urlPath.handoverTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true,
    checkPath: urlPath.handoverTicket.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.handoverTicketAdd.location,
    Layout: MainLayoutV2,
    Component: AddHandoverTicketV2,

    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true,
    checkPath: urlPath.handoverTicket.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.handoverTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    Component: AddHandoverTicketV2,

    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true,
    checkPath: urlPath.handoverTicket.location,
    permission: PermissionEn.update
  },
  //#endregion TICKET RAISING

  //#region HRM
  {
    path: urlPath.humanResource.location,
    Layout: NewLayout,
    Component: HumanResource
  },

  //#region C&B
  {
    path: urlPath.cnbEmployee.location,
    Layout: NewLayout,
    Component: Employee,
    checkPath: urlPath.cnbEmployee.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.cnbEmployeeAdd.location,
    Layout: NewLayout,

    Component: AddEmployee,
    checkPath: urlPath.cnbEmployee.location,
    permission: PermissionEn.create
  },
  {
    path: urlPath.cnbEmployeeQR.location,
    Layout: NewLayout,
    Component: EmployeeQrCode,
    checkPath: urlPath.cnbEmployee.location,
    permission: PermissionEn.get_qr
  },
  {
    path: `${urlPath.cnbEmployeeEdit.location}/:id`,
    Layout: NewLayout,

    Component: AddEmployee,
    checkPath: urlPath.cnbEmployee.location,
    permission: PermissionEn.update
  },
  {
    path: `${urlPath.cnbEmployeeDetail.location}/:id`,
    Layout: NewLayout,
    Component: EmployeeDetail,
    checkPath: urlPath.cnbEmployee.location,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.cnbDepartment.location,
    Layout: NewLayout,
    Component: Department,
    checkPath: urlPath.cnbDepartment.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.cnbJobCategory.location,
    Layout: NewLayout,
    Component: JobCategory,
    checkPath: urlPath.cnbJobCategory.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.cnbJobCategoryAdd.location,
    Layout: NewLayout,

    Component: AddJobCategory,
    checkPath: urlPath.cnbJobCategory.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.cnbJobCategoryEdit.location}/:id`,
    Layout: NewLayout,

    Component: AddJobCategory,
    checkPath: urlPath.cnbJobCategory.location,
    permission: PermissionEn.update
  },
  {
    path: urlPath.recruitmentJobDescription.location,
    Layout: NewLayout,
    Component: CnbJobDescription,
    checkPath: urlPath.recruitmentJobDescription.location,
    permission: PermissionEn.read
  },
  //#endregion C&B

  //#region RECRUITMENT
  {
    path: urlPath.recruitmentProcess.location,
    Layout: NewLayout,
    Component: RecruitmentProcess,
    checkPath: urlPath.recruitmentProcess.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.recruitmentJobDescription.location,
    Layout: NewLayout,
    Component: CnbJobDescription,
    checkPath: urlPath.recruitmentJobDescription.location,
    permission: PermissionEn.read
  },

  {
    path: urlPath.recruitmentPlan.location,
    Layout: NewLayout,
    Component: RecruitmentPlan,
    checkPath: urlPath.recruitmentPlan.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.jobRequirements.location,
    Layout: NewLayout,
    Component: JobRequirement,
    checkPath: urlPath.jobRequirements.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.recruitmentCandidate.location,
    Layout: NewLayout,
    Component: Candidate,
    checkPath: urlPath.recruitmentCandidate.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.interviewInvitationLetter.location,
    Layout: NewLayout,
    Component: InterviewInvitationLetter
  },
  {
    path: urlPath.resultNotificationLetter.location,
    Layout: NewLayout,
    Component: ResultNotificationLetter,
    checkPath: urlPath.resultNotificationLetter.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.jobOfferLetter.location,
    Layout: NewLayout,
    Component: JobOfferLetter,
    checkPath: urlPath.jobOfferLetter.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.employmentConfirmationNotice.location,
    Layout: NewLayout,
    Component: EmploymentConfirmationNotice,
    checkPath: urlPath.employmentConfirmationNotice.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.recruitmentActivityResults.location,
    Layout: NewLayout,
    Component: RecruitmentActivityResults,
    checkPath: urlPath.recruitmentActivityResults.location,
    permission: PermissionEn.read
  },
  //#endregion RECRUITMENT

  //#region TRAINING
  {
    path: urlPath.trainingProcess.location,
    Layout: NewLayout,
    Component: TrainingProcess,
    checkPath: urlPath.trainingProcess.location,
    permission: PermissionEn.read
  },

  {
    path: urlPath.trainingDocumentation.location,
    Layout: NewLayout,
    Component: TrainingDocumentation,
    checkPath: urlPath.trainingDocumentation.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingJobDescription.location,
    Layout: NewLayout,
    Component: CnbJobDescription,
    checkPath: urlPath.trainingJobDescription.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingPlan.location,
    Layout: NewLayout,
    Component: TrainingPlan,
    checkPath: urlPath.trainingPlan.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingRequirements.location,
    Layout: NewLayout,
    Component: TrainingRequirement,
    checkPath: urlPath.trainingRequirements.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingSupplier.location,
    Layout: NewLayout,
    Component: TrainingSupplier,
    checkPath: urlPath.trainingSupplier.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingProgram.location,
    Layout: NewLayout,
    Component: TrainingProgram,
    checkPath: urlPath.trainingProgram.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingCost.location,
    Layout: NewLayout,
    Component: TrainingCost,
    checkPath: urlPath.trainingCost.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingParticipant.location,
    Layout: NewLayout,
    Component: TrainingParticipant,
    checkPath: urlPath.trainingParticipant.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingInvitationLetter.location,
    Layout: NewLayout,
    Component: TrainingInvitationLetter,
    checkPath: urlPath.trainingInvitationLetter.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingResult.location,
    Layout: NewLayout,
    Component: TrainingResult,
    checkPath: urlPath.trainingResult.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingResultNotification.location,
    Layout: NewLayout,
    Component: TrainingResultNotification,
    checkPath: urlPath.trainingResultNotification.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.trainingActivityResults.location,
    Layout: NewLayout,
    Component: TrainingActivityResult,
    checkPath: urlPath.trainingActivityResults.location,
    permission: PermissionEn.read
  },
  //#endregion TRAINING

  //#endregion HRM

  //#region STANDARDS QUALITY
  {
    path: standardsQualityPath.standardsQuality,
    Layout: NewLayout,
    Component: StandardQuality
  },
  {
    path: standardsQualityPath.qcTemplate,
    Layout: NewLayout,
    Component: QcTemplate,
    checkPath: standardsQualityPath.qcTemplate,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.qcTemplateAdd,
    Layout: NewLayout,
    Component: AddQcTemplate,
    checkPath: standardsQualityPath.qcTemplate,
    permission: PermissionEn.create
  },
  {
    path: `${standardsQualityPath.qcTemplateEdit}/:id`,
    Layout: NewLayout,
    Component: AddQcTemplate,
    checkPath: standardsQualityPath.qcTemplate,
    permission: PermissionEn.update
  },
  {
    path: `${standardsQualityPath.qcTemplateDetail}/:id`,
    Layout: NewLayout,
    Component: AddQcTemplate,
    checkPath: standardsQualityPath.qcTemplate,
    permission: PermissionEn.viewDetail
  },
  {
    path: standardsQualityPath.qcCheck,
    Layout: NewLayout,
    Component: QcCheck,
    checkPath: standardsQualityPath.qcCheck,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.qcCheckAdd,
    Layout: NewLayout,
    Component: AddQcCheck,
    checkPath: standardsQualityPath.qcCheck,
    permission: PermissionEn.create
  },
  {
    path: `${standardsQualityPath.qcCheckEdit}/:id`,
    Layout: NewLayout,
    Component: AddQcCheck,
    checkPath: standardsQualityPath.qcCheck,
    permission: PermissionEn.update
  },
  {
    path: `${standardsQualityPath.qcCheckDetail}/:id`,
    Layout: NewLayout,
    Component: AddQcCheck,
    checkPath: standardsQualityPath.qcCheck,
    permission: PermissionEn.viewDetail
  },
  {
    path: standardsQualityPath.productionPlan,
    Layout: NewLayout,
    Component: ProductionPlan,
    checkPath: standardsQualityPath.productionPlan,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.productionPlanAdd,
    Layout: NewLayout,
    Component: AddProductionPlan,
    checkPath: standardsQualityPath.productionPlan,
    permission: PermissionEn.create
  },
  {
    path: `${standardsQualityPath.productionPlanEdit}/:id`,
    Layout: NewLayout,
    Component: AddProductionPlan,
    checkPath: standardsQualityPath.productionPlan,
    permission: PermissionEn.update
  },
  {
    path: `${standardsQualityPath.productionPlanDetail}/:id`,
    Layout: NewLayout,
    Component: ProductionPlanDetail,
    checkPath: standardsQualityPath.productionPlan,
    permission: PermissionEn.viewDetail
  },
  {
    path: standardsQualityPath.standard,
    Layout: NewLayout,
    Component: Standard,
    checkPath: standardsQualityPath.standard,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.checkList,
    Layout: NewLayout,
    Component: CheckList,
    checkPath: standardsQualityPath.checkList,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.certificationAssessmentPlan,
    Layout: NewLayout,
    Component: CertificationAssessmentPlan,
    checkPath: standardsQualityPath.certificationAssessmentPlan,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.qualityInspectionRequirement,
    Layout: NewLayout,
    Component: QualityInspectionRequirement,
    checkPath: standardsQualityPath.qualityInspectionRequirement,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.qualityControlProcess,
    Layout: NewLayout,
    Component: QualityControlProcess,
    checkPath: standardsQualityPath.qualityControlProcess,
    permission: PermissionEn.read
  },
  {
    path: standardsQualityPath.materialQualityManagement,
    Layout: NewLayout,
    Component: MaterialQualityManagement,
    checkPath: standardsQualityPath.materialQualityManagement,
    permission: PermissionEn.read
  },
  //#endregion STANDARDS QUALITY

  // //#region ITEM MASTER DATA
  // {
  //   path: urlPath.itemMasterData.location,
  //   Layout: NewLayout,
  //   Component: ItemMasterDataHome
  // },
  // {
  //   path: urlPath.item.location,
  //   Layout: NewLayout,
  //   Component: ItemMasterData,
  //   checkPath: urlPath.item.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.itemAdd.location,
  //   Layout: NewLayout,

  //   Component: AddItemMasterData,
  //   checkPath: urlPath.item.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.itemEdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddItemMasterData,
  //   checkPath: urlPath.item.location,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: `${urlPath.itemDetail.location}/:id`,
  //   Layout: NewLayout,
  //   Component: AddItemMasterData,
  //   checkPath: urlPath.item.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.itemType.location,
  //   Layout: NewLayout,
  //   Component: ItemType,
  //   checkPath: urlPath.itemType.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.itemTypeAdd.location,
  //   Layout: NewLayout,

  //   Component: AddItemType,
  //   checkPath: urlPath.itemType.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.itemTypeEdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddItemType,
  //   checkPath: urlPath.itemType.location,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: `${urlPath.itemTypeDetail.location}/:id`,
  //   Layout: NewLayout,
  //   Component: ItemTypeDetail,
  //   checkPath: urlPath.itemType.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.itemGroup.location,
  //   Layout: NewLayout,
  //   Component: ItemGroup,
  //   checkPath: urlPath.itemGroup.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.itemGroupAdd.location,
  //   Layout: NewLayout,

  //   Component: AddItemGroup,
  //   checkPath: urlPath.itemGroup.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.itemGroupEdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddItemGroup,
  //   checkPath: urlPath.itemGroup.location,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: `${urlPath.itemGroupDetail.location}/:id`,
  //   Layout: NewLayout,
  //   Component: ItemGroupDetail,
  //   checkPath: urlPath.itemGroup.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.model.location,
  //   Layout: NewLayout,
  //   Component: Model,
  //   checkPath: urlPath.model.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.modelAdd.location,
  //   Layout: NewLayout,

  //   Component: AddModel,
  //   checkPath: urlPath.model.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.modelEdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddModel,
  //   checkPath: urlPath.model.location,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: `${urlPath.modelDetail.location}/:id`,
  //   Layout: NewLayout,
  //   Component: ModelDetail,
  //   checkPath: urlPath.model.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.itemSpec.location,
  //   Layout: NewLayout,
  //   Component: ItemSpec,
  //   checkPath: urlPath.itemSpec.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.itemSpecAdd.location,
  //   Layout: NewLayout,

  //   Component: AddItemSpec,
  //   checkPath: urlPath.itemSpec.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.itemSpecEdit.location}/:id`,
  //   Layout: NewLayout,

  //   Component: AddItemSpec,
  //   checkPath: urlPath.itemSpec.location,
  //   permission: PermissionEn.update
  // },
  // {
  //   path: `${urlPath.itemSpecDetail.location}/:id`,
  //   Layout: NewLayout,
  //   Component: ItemSpecDetail,
  //   checkPath: urlPath.itemSpec.location,
  //   permission: PermissionEn.viewDetail
  // },
  // {
  //   path: urlPath.accountingItem.location,
  //   Layout: NewLayout,
  //   Component: AccountingItem,
  //   checkPath: urlPath.accountingItem.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: accountingPath.accountingAccount,
  //   Layout: NewLayout,
  //   Component: GeneralAccountingAccount,
  //   checkPath: accountingPath.accountingAccount,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.specification.location,
  //   Layout: NewLayout,
  //   Component: Specification,
  //   checkPath: urlPath.specification.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.itemTechnicalSpecification.location,
  //   Layout: NewLayout,
  //   Component: ItemTechnicalSpecification,
  //   checkPath: urlPath.itemTechnicalSpecification.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.dimensionalStandard.location,
  //   Layout: NewLayout,
  //   Component: DimensionalStandard,
  //   checkPath: urlPath.dimensionalStandard.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.accountingAccountType.location,
  //   Layout: NewLayout,
  //   Component: AccountingAccountType,
  //   checkPath: urlPath.accountingAccountType.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.accountingObjectType.location,
  //   Layout: NewLayout,
  //   Component: AccountingObjectType,
  //   checkPath: urlPath.accountingObjectType.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.generalAccountingAccount.location,
  //   Layout: NewLayout,
  //   Component: GeneralAccountingAccount,
  //   checkPath: urlPath.generalAccountingAccount.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.process.location,
  //   Layout: NewLayout,
  //   Component: Process,
  //   checkPath: urlPath.process.location,
  //   permission: PermissionEn.read
  // },
  // {
  //   path: urlPath.processAdd.location,
  //   Layout: NewLayout,
  //   Component: AddProcess,
  //   checkPath: urlPath.process.location,
  //   permission: PermissionEn.create
  // },
  // {
  //   path: `${urlPath.processEdit.location}/:id`,
  //   Layout: NewLayout,
  //   Component: AddProcess,
  //   checkPath: urlPath.process.location,
  //   permission: PermissionEn.update
  // },
  // // {
  // //   path: `${urlPath.processDetail.location}/:id`,
  // //   Layout: NewLayout,
  // //   Component: ProcessDetail,
  // //   checkPath: urlPath.process.location,
  // //   permission: PermissionEn.viewDetail
  // // },

  // // ĐƠN GIÁ KHOÁN
  // {
  //   path: urlPath.contractualUnitPrice.location,
  //   Layout: NewLayout,
  //   Component: ContractualUnitPrice,
  //   checkPath: urlPath.contractualUnitPrice.location,
  //   permission: PermissionEn.read
  // },
  // // {
  // //   path: urlPath.contractualUnitPriceAdd.location,
  // //   Layout: NewLayout,
  // //   Component: ContractualUnitPriceForm,
  // //   checkPath: urlPath.contractualUnitPrice.location,
  // //   permission: PermissionEn.create
  // // },
  // // {
  // //   path: `${urlPath.contractualUnitPriceEdit.location}/:id`,
  // //   Layout: NewLayout,
  // //   Component: ContractualUnitPriceForm,
  // //   checkPath: urlPath.contractualUnitPrice.location,
  // //   permission: PermissionEn.update
  // // },
  // // {
  // //   path: `${urlPath.contractualUnitPriceDetail.location}/:id`,
  // //   Layout: NewLayout,
  // //   Component: ContractualUnitPriceForm,
  // //   checkPath: urlPath.contractualUnitPrice.location,
  // //   permission: PermissionEn.viewDetail
  // // },
  // //#endregion ITEM MASTER DATA

  //#region PRODUCTION

  // BOM
  {
    path: urlPath.bom.location,
    Layout: NewLayout,
    Component: BOM,
    checkPath: urlPath.bom.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.bomAdd.location,
    Layout: NewLayout,

    Component: AddBOM,
    checkPath: urlPath.bom.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.bomEdit.location}/:id`,
    Layout: NewLayout,

    Component: AddBOM,
    checkPath: urlPath.bom.location,
    permission: PermissionEn.update
  },

  // DELIVERY ORDER
  {
    path: productionPath.deliveryOrder,
    Layout: NewLayout,
    Component: DeliveryOrder,
    checkPath: productionPath.deliveryOrder,
    permission: PermissionEn.read
  },
  {
    path: productionPath.deliveryOrderAdd,
    Layout: NewLayout,

    Component: AddDeliveryOrder,
    checkPath: productionPath.deliveryOrder,
    permission: PermissionEn.create
  },
  {
    path: `${productionPath.deliveryOrderEdit}/:id`,
    Layout: NewLayout,

    Component: AddDeliveryOrder,
    checkPath: productionPath.deliveryOrder,
    permission: PermissionEn.update
  },
  // Raw Matterial Estimate
  {
    path: urlPath.rawMatEst.location,
    Layout: NewLayout,
    Component: RawMatEst,
    checkPath: urlPath.rawMatEst.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.rawMatEstAdd.location,
    Layout: NewLayout,

    Component: AddRawMatEst,
    checkPath: urlPath.rawMatEst.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.rawMatEstEdit.location}/:id`,
    Layout: NewLayout,

    Component: AddRawMatEst,
    checkPath: urlPath.rawMatEst.location,
    permission: PermissionEn.update
  },
  // DELIVERY REQUEST
  {
    path: productionPath.deliveryRequest,
    Layout: NewLayout,
    Component: DeliveryRequest,
    checkPath: productionPath.deliveryRequest,
    permission: PermissionEn.read
  },
  {
    path: productionPath.deliveryRequestAdd,
    Layout: NewLayout,

    Component: AddDeliveryRequest,
    checkPath: productionPath.deliveryRequest,
    permission: PermissionEn.create
  },
  {
    path: `${productionPath.deliveryRequestEdit}/:id`,
    Layout: NewLayout,

    Component: AddDeliveryRequest,
    checkPath: productionPath.deliveryRequest,
    permission: PermissionEn.update
  },
  // PACKAGING REQUEST
  {
    path: urlPath.packagingRequest.location,
    Layout: NewLayout,
    Component: PackagingRequest,
    checkPath: urlPath.packagingRequest.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.packagingRequestAdd.location,
    Layout: NewLayout,

    Component: AddPackagingRequest,
    checkPath: urlPath.packagingRequest.location,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.packagingRequestEdit.location}/:id`,
    Layout: NewLayout,

    Component: AddPackagingRequest,
    checkPath: urlPath.packagingRequest.location,
    permission: PermissionEn.update
  },
  // BUDGET PLAN
  {
    path: urlPath.productionBudgetPlan.location,
    Layout: NewLayout,
    Component: InstallationBudgetPlan,
    checkPath: urlPath.productionBudgetPlan.location,
    permission: PermissionEn.read
  },
  // PROCESS
  {
    path: urlPath.productionProcess.location,
    Layout: NewLayout,
    Component: ProductionProcess,
    checkPath: urlPath.productionProcess.location,
    permission: PermissionEn.read
  },
  // PROCESS
  {
    path: urlPath.productionQualityControl.location,
    Layout: NewLayout,
    Component: QualityControl,
    checkPath: urlPath.productionQualityControl.location,
    permission: PermissionEn.read
  },

  //#endregion PRODUCTION

  //#region FINANCE
  {
    path: urlPath.finance.location,
    Layout: NewLayout,
    Component: Finance
  },
  {
    path: urlPath.cashFlowPlan.location,
    Layout: NewLayout,
    Component: CashFlowPlan,
    checkPath: urlPath.cashFlowPlan.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.financeReport.location,
    Layout: NewLayout,
    Component: FinanceReport,
    checkPath: urlPath.financeReport.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.paymentPlan.location,
    Layout: NewLayout,
    Component: PaymentPlan,
    checkPath: urlPath.paymentPlan.location,
    permission: PermissionEn.read
  },
  {
    path: urlPath.cashFlowReport.location,
    Layout: NewLayout,
    Component: CashFlowReport,
    checkPath: urlPath.cashFlowReport.location,
    permission: PermissionEn.read
  },
  //#endregion FINANCE

  //#region ACCOUNTING
  {
    path: accountingPath.accounting,
    Layout: NewLayout,
    Component: Accounting
  },

  {
    path: accountingPath.accountingPaymentRequestTicket,
    Layout: NewLayout,
    Component: PaymentRequestTicket,
    checkPath: accountingPath.accountingAccount,
    permission: PermissionEn.read
  },

  // GRPO
  {
    path: accountingPath.grpo,
    Layout: NewLayout,
    Component: GRPO,
    checkPath: accountingPath.grpo,
    permission: PermissionEn.read
  },
  {
    path: accountingPath.grpoAdd,
    Layout: NewLayout,

    Component: AddGRPO,
    checkPath: accountingPath.grpo,
    permission: PermissionEn.create
  },
  {
    path: `${accountingPath.grpoEdit}/:id`,
    Layout: NewLayout,

    Component: AddGRPO,
    checkPath: accountingPath.grpo,
    permission: PermissionEn.update
  },
  {
    path: `${accountingPath.grpoDetail}/:id`,
    Layout: NewLayout,
    Component: GRPODetail,
    checkPath: accountingPath.grpo,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${accountingPath.grpoDetailCode}/:code`,
    Layout: NewLayout,
    Component: GRPODetail,
    checkPath: accountingPath.grpo,
    permission: PermissionEn.viewDetail
  },
  //#endregion ACCOUNTING

  //#region  CONTRACT EXECUTION
  {
    path: urlPath.contractExecution.location,
    Layout: NewLayout,
    Component: ContractExecution
  },
  //#region installation
  //INSTALLATION ORDER
  {
    path: urlPath.installationOrder.location,
    Layout: NewLayout,
    Component: InstallationOrder,
    checkPath: urlPath.installationOrder.location,
    permission: PermissionEn.read
  },
  //INSTALLATION DELIVERY REQUEST
  {
    path: urlPath.installationDeliveryOrder.location,
    Layout: NewLayout,
    Component: DeliveryOrder,
    checkPath: urlPath.installationDeliveryOrder.location,
    permission: PermissionEn.read
  },
  //WAREHOUSE TRANSFER REQUEST
  {
    path: urlPath.installationWareHouseTransferRequest.location,
    Layout: NewLayout,
    Component: WarehouseTransferRequest,
    checkPath: urlPath.installationWareHouseTransferRequest.location,
    permission: PermissionEn.read
  },
  //INSTALLATION BUDGET PLANNING
  {
    path: urlPath.installationBudgetPlan.location,
    Layout: NewLayout,
    Component: InstallationBudgetPlan,
    checkPath: urlPath.installationBudgetPlan.location,
    permission: PermissionEn.read
  },
  //INSTALLATION PROCESS
  {
    path: urlPath.installationProcess.location,
    Layout: NewLayout,
    Component: InstallationProcess,
    checkPath: urlPath.installationProcess.location,
    permission: PermissionEn.read
  },
  //INSTALLATION QUALITY CONTROL
  {
    path: urlPath.installationQualityControl.location,
    Layout: NewLayout,
    Component: QualityControl,
    checkPath: urlPath.installationQualityControl.location,
    permission: PermissionEn.read
  },
  //#endregion installation

  //#region maintenance
  //MAINTENANCE ORDER
  {
    path: urlPath.maintenanceOrder.location,
    Layout: NewLayout,
    Component: InstallationOrder,
    checkPath: urlPath.maintenanceOrder.location,
    permission: PermissionEn.read
  },
  //MAINTENANCE DELIVERY REQUEST
  {
    path: urlPath.maintenanceDeliveryOrder.location,
    Layout: NewLayout,
    Component: DeliveryOrder,
    checkPath: urlPath.maintenanceDeliveryOrder.location,
    permission: PermissionEn.read
  },
  //MAINTENANCE REQUEST
  {
    path: urlPath.maintenanceRequest.location,
    Layout: NewLayout,
    Component: InstallationRequest,
    checkPath: urlPath.maintenanceRequest.location,
    permission: PermissionEn.read
  },
  //WAREHOUSE TRANSFER REQUEST
  {
    path: urlPath.maintenanceWareHouseTransferRequest.location,
    Layout: NewLayout,
    Component: WarehouseTransferRequest,
    checkPath: urlPath.maintenanceWareHouseTransferRequest.location,
    permission: PermissionEn.read
  },
  //MAINTENANCE BUDGET PLANNING
  {
    path: urlPath.maintenanceBudgetPlan.location,
    Layout: NewLayout,
    Component: InstallationBudgetPlan,
    checkPath: urlPath.maintenanceBudgetPlan.location,
    permission: PermissionEn.read
  },
  //MAINTENANCE PROCESS
  {
    path: urlPath.maintenanceProcess.location,
    Layout: NewLayout,
    Component: InstallationProcess,
    checkPath: urlPath.maintenanceProcess.location,
    permission: PermissionEn.read
  },
  //MAINTENANCE QUALITY CONTROL
  {
    path: urlPath.maintenanceQualityControl.location,
    Layout: NewLayout,
    Component: QualityControl,
    checkPath: urlPath.maintenanceQualityControl.location,
    permission: PermissionEn.read
  },
  //#endregion maintenance

  //#region delivery
  //DELIVERY ORDER
  // {
  //   path: deliveryPath.deliveryOrder,
  //   Layout: NewLayout,
  //   Component: InstallationOrder,
  //   checkPath: deliveryPath.deliveryOrder,
  //   permission: PermissionEn.read
  // },
  //DELIVERY REQUEST
  // {
  //   path: deliveryPath.deliveryRequest,
  //   Layout: NewLayout,
  //   Component: InstallationRequest,
  //   checkPath: deliveryPath.deliveryRequest,
  //   permission: PermissionEn.read
  // },
  //WAREHOUSE TRANSFER REQUEST
  // {
  //   path: deliveryPath.deliveryWareHouseTransferRequest,
  //   Layout: NewLayout,
  //   Component: WarehouseTransferRequest,
  //   checkPath: deliveryPath.deliveryWareHouseTransferRequest,
  //   permission: PermissionEn.read
  // },
  // //DELIVERY BUDGET PLANNING
  // {
  //   path: deliveryPath.deliveryBudgetPlan,
  //   Layout: NewLayout,
  //   Component: InstallationBudgetPlan,
  //   checkPath: deliveryPath.deliveryBudgetPlan,
  //   permission: PermissionEn.read
  // },
  // //DELIVERY PROCESS
  // {
  //   path: deliveryPath.deliveryProcess,
  //   Layout: NewLayout,
  //   Component: InstallationProcess,
  //   checkPath: deliveryPath.deliveryProcess,
  //   permission: PermissionEn.read
  // },
  // //DELIVERY QUALITY CONTROL
  // {
  //   path: deliveryPath.deliveryQualityControl,
  //   Layout: NewLayout,
  //   Component: QualityControl,
  //   checkPath: deliveryPath.deliveryQualityControl,
  //   permission: PermissionEn.read
  // },
  //#endregion delivery
  //#endregion CONTRACT EXECUTION

  ...accountingRoutes,
  ...inventoryRoutes,
  ...rndRoutes,
  ...productionRoutes,
  ...planningRoutes,
  ...saleRoutes,
  ...marketingRoutes,
  ...preSaleRoutes,
  ...crmMasterDataRoutes,
  ...itemMasterDataRoutes,
  ...deliveryRoutes,
  ...installationRoutes,
  ...purchaseRoutes
];

const commonRoutes: RouteType[] = [
  {
    path: urlPath.signinOidc.location,
    Component: SigninOidc
  },
  {
    path: urlPath.signoutOidc.location,
    Component: SignoutOidc
  },
  {
    path: urlPath.signoutCallbackOidc.location,
    Component: SignoutCallbackOidc
  },
  {
    path: urlPath.serverError.location,
    Layout: MainLayoutV2,
    Component: InternalServer
  },
  {
    path: '*',
    Layout: MainLayoutV2,
    Component: NotFound
  }
];

export default function useRouteElements(resources: IResourceFullAccessDetail[], resourcePermissionLoading: boolean) {
  const permissionResource = usePermissionNew(
    [...routesV1, ...routesV2, ...commonRoutes].map((x) => x.path),
    resources
  );

  const hasPermission = (path: string, permission: PermissionEn) => {
    const similarResource = permissionResource?.filter((x) => path?.includes(x?.path as string));
    const mostSimilarPath = similarResource?.reduce((prevPath, currPath) => {
      return (currPath?.path?.length || 0) > (prevPath?.path?.length || 0) ? currPath : prevPath;
    }, similarResource[0]);
    if (mostSimilarPath) {
      return mostSimilarPath?.permissions?.includes(permission);
    }
    return false;
  };

  const routeElements = useRoutes(
    [...routesV1, ...routesV2, ...commonRoutes].map(
      ({
        path,
        redirect,
        Layout,
        SubLayout,
        Component,
        permission,
        checkPath,
        hasSidebar,
        hasSubSidebar,
        isDisableFooter,
        isHideDetailLayout,
        hasSubHeader
      }) => {
        // const innerPaths = isUsePathV2 ? pathsV2 : paths;
        const resComponent = redirect ? <Navigate to={redirect} /> : <Component />;

        if (!SubLayout && Layout) {
          return {
            path,
            element: (
              <Layout isDisableFooter={isDisableFooter} hasSidebar={hasSidebar}>
                <Spin spinning={!!resourcePermissionLoading || !resources} fullscreen />
                {!resourcePermissionLoading && !!resources ? (
                  <Suspense>
                    {
                      // checkPath && !paths?.includes(checkPath) ? (
                      //   <NotFound />
                      // ) :
                      permission && !hasPermission(path, permission) ? <Forbidden /> : resComponent
                    }
                  </Suspense>
                ) : (
                  <></>
                )}
              </Layout>
            )
          };
        } else if (SubLayout && Layout) {
          return {
            path,
            element: (
              <Layout isDisableFooter={isDisableFooter}>
                <Suspense>
                  <SubLayout
                    hasSidebar={hasSidebar}
                    hasSubHeader={hasSubHeader}
                    hasSubSidebar={hasSubSidebar}
                    isHideDetailLayout={isHideDetailLayout}
                  >
                    <Suspense>
                      {
                        // checkPath && !paths?.includes(checkPath) ? (
                        //   <NotFound />
                        // ) :
                        permission && !hasPermission(path, permission) ? <Forbidden /> : resComponent
                      }
                      {/* {permission ? (
                        hasPermission(path, permission) ? (
                          resComponent
                        ) : (
                          <Forbidden />
                        )
                      ) : !checkPath || paths.length === 0 || paths?.includes(checkPath) ? (
                        resComponent
                      ) : (
                        <NotFound />
                      )} */}
                    </Suspense>
                  </SubLayout>
                </Suspense>
              </Layout>
            )
          };
        } else
          return {
            path,
            element: resComponent
          };
      }
    )
  );

  return routeElements;
}
