import type { HierarchicalItem } from 'src/types/elements.type';
import type { ITechnicianType, TechnicianTypeAdd } from 'src/types/master_data/technicianType.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/technician-types';

export const getTechnicianTypes = async (
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<ITechnicianType>>(`${controller}`, {
    params: {
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getTechnicianType = async (id: number) => {
  return http.get<ITechnicianType>(`${controller}/${id}`);
};

export const addTechnicianType = async (technicianType: TechnicianTypeAdd) => {
  return http.post<ITechnicianType>(controller, technicianType);
};

export const updateTechnicianType = async (id: number, technicianType: TechnicianTypeAdd) => {
  return await http.put<ITechnicianType[]>(`${controller}/${id}`, technicianType);
};

export const deleteTechnicianType = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getTechnicianTypesDropdown = async () => {
  return await http.get<HierarchicalItem[]>(`${controller}/dropdown`);
};
