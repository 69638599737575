import { lazy } from 'react';
import { PermissionEn } from 'src/constants/permission.enum';
import productionPath from 'src/constants/urlPath/erp/productionPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';

const ProductionOrder = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionOrder'));
const AddProductionOrder = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/ProductionOrder/AddProductionOrder')
);

const ITR = lazy(() => import('src/pages/Inventory/ITR'));
const ITRForm = lazy(() => import('src/pages/Inventory/ITR/ITRForm'));

const IT = lazy(() => import('src/pages/Inventory/IT'));
const ITForm = lazy(() => import('src/pages/Inventory/IT/ITForm'));

const ProductionRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionRequest'));
const AddProductionRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/ProductionRequest/AddProductionRequest')
);

const AcceptanceReport = lazy(() => import('src/pages/ERP/ContractExecution/Production/AcceptanceReport'));

const AcceptanceMinute = lazy(() => import('src/pages/ERP/ContractExecution/Production/AcceptanceMinute'));
const AcceptanceMinuteForm = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/AcceptanceMinute/AcceptanceMinuteForm')
);

const RawMatBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatBom'));
const AddRawMatBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatBom/AddRawMatBom'));
const AddLaborBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/LaborBom/AddLaborBom'));
const AddEquipmentBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/EquipmentBom/AddEquipmentBom'));

const TaskManagement = lazy(() => import('src/pages/ERP/ContractExecution/Production/TaskManagement'));

const EstimatedProductionExpense = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/EstimatedProductionExpense')
);
const AddEstimatedProductionExpense = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/EstimatedProductionExpense/AddEstimatedProductionExpense')
);
// const AddTaskManagement = lazy(() => import('src/pages/ERP/ContractExecution/Production/TaskManagement/AddTaskManagement'));

const productionRoutes: RouteType[] = [
  // ITR - Yêu cầu chuyển kho
  {
    path: productionPath.itr,
    Layout: NewLayout,
    Component: ITR,
    checkPath: productionPath.itr,
    permission: PermissionEn.read
  },
  {
    path: productionPath.itrAdd,
    Layout: NewLayout,
    Component: ITRForm,
    checkPath: productionPath.itr,
    permission: PermissionEn.create
  },
  {
    path: `${productionPath.itrEdit}/:id`,
    Layout: NewLayout,
    Component: ITRForm,
    checkPath: productionPath.itr,
    permission: PermissionEn.update
  },
  {
    path: `${productionPath.itrDetail}/:id`,
    Layout: NewLayout,
    Component: ITRForm,
    checkPath: productionPath.itr,
    permission: PermissionEn.viewDetail
  },

  // IT - Phiếu chuyển kho
  {
    path: productionPath.it,
    Layout: NewLayout,
    Component: IT,
    checkPath: productionPath.it,
    permission: PermissionEn.read
  },
  {
    path: `${productionPath.itDetail}/:id`,
    Layout: NewLayout,
    Component: ITForm,
    checkPath: productionPath.it,
    permission: PermissionEn.viewDetail
  },

  // Production order - Phiếu yêu cầu sản xuất
  {
    path: productionPath.productionOrder,
    Layout: NewLayout,
    Component: ProductionOrder
  },
  {
    path: productionPath.productionOrderAdd,
    Layout: NewLayout,
    Component: AddProductionOrder
  },
  {
    path: `${productionPath.productionOrderEdit}/:id`,
    Layout: NewLayout,
    Component: AddProductionOrder
  },
  {
    path: `${productionPath.productionOrderDetail}/:id`,
    Layout: NewLayout,
    Component: AddProductionOrder
  },
  {
    path: `${productionPath.productionOrderDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddProductionOrder
  },

  // Production request - Lệnh sản xuất
  {
    path: productionPath.productionRequest,
    Layout: NewLayout,
    Component: ProductionRequest
  },
  {
    path: productionPath.productionRequestAdd,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  {
    path: `${productionPath.productionRequestEdit}/:id`,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  {
    path: `${productionPath.productionRequestDetail}/:id`,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  {
    path: `${productionPath.productionRequestDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  // Acceptance report - Báo cáo  nghiệm thu
  {
    path: productionPath.acceptanceReport,
    Layout: NewLayout,
    Component: AcceptanceReport
  },
  // Acceptance minute - Biên bản nghiệm thu thành phẩm
  {
    path: productionPath.acceptanceMinute,
    Layout: NewLayout,
    Component: AcceptanceMinute,
    checkPath: productionPath.acceptanceMinute,
    permission: PermissionEn.read
  },
  {
    path: productionPath.acceptanceMinuteAdd,
    Layout: NewLayout,
    Component: AcceptanceMinuteForm,
    checkPath: productionPath.acceptanceMinute,
    permission: PermissionEn.create
  },
  {
    path: `${productionPath.acceptanceMinuteEdit}/:id`,
    Layout: NewLayout,
    Component: AcceptanceMinuteForm,
    checkPath: productionPath.acceptanceMinute,
    permission: PermissionEn.update
  },
  {
    path: `${productionPath.acceptanceMinuteDetail}/:id`,
    Layout: NewLayout,
    Component: AcceptanceMinuteForm,
    checkPath: productionPath.acceptanceMinute,
    permission: PermissionEn.viewDetail
  },

  // Raw Mat BOM - BOM Nguyên vật liệu
  {
    path: productionPath.rawMatBom,
    Layout: NewLayout,
    Component: RawMatBom,
    checkPath: productionPath.rawMatBom,
    permission: PermissionEn.read
  },
  {
    path: productionPath.rawMatBomAdd,
    Layout: NewLayout,
    Component: AddRawMatBom
  },
  {
    path: `${productionPath.rawMatBomEdit}/:id`,
    Layout: NewLayout,
    Component: AddRawMatBom
  },
  {
    path: `${productionPath.rawMatBomDetail}/:id`,
    Layout: NewLayout,
    Component: AddRawMatBom
  },
  // Labor BOM - BOM Nhân công
  {
    path: productionPath.laborBomAdd,
    Layout: NewLayout,
    Component: AddLaborBom
  },
  {
    path: `${productionPath.laborBomEdit}/:code`,
    Layout: NewLayout,
    Component: AddLaborBom
  },
  {
    path: `${productionPath.laborBomDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddLaborBom
  },
  // Equipment BOM - BOM công cụ dụng cụ
  {
    path: productionPath.equipmentBomAdd,
    Layout: NewLayout,
    Component: AddEquipmentBom
  },
  {
    path: `${productionPath.equipmentBomEdit}/:code`,
    Layout: NewLayout,
    Component: AddEquipmentBom
  },
  {
    path: `${productionPath.equipmentBomDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddEquipmentBom
  },
  // Task Management - Quy trình sản xuất
  {
    path: productionPath.taskManagement,
    Layout: NewLayout,
    Component: TaskManagement
  },

  // Estimated Production Expense - Dự toán sản xuất
  {
    path: productionPath.estimatedProductionExpense,
    Layout: NewLayout,
    Component: EstimatedProductionExpense
  },
  {
    path: productionPath.estimatedProductionExpenseAdd,
    Layout: NewLayout,
    Component: AddEstimatedProductionExpense
  },
  {
    path: `${productionPath.estimatedProductionExpenseEdit}/:id`,
    Layout: NewLayout,
    Component: AddEstimatedProductionExpense
  },
  {
    path: `${productionPath.estimatedProductionExpenseDetail}/:id`,
    Layout: NewLayout,
    Component: AddEstimatedProductionExpense
  }
];

export default productionRoutes;
