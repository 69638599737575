import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TreeSelect } from 'antd';
import { type FlatItem, type TreeItem } from 'src/types/elements.type';
import { buildHierarchySelect } from 'src/utils/collection';
import { getDepartmentsDropdown } from 'src/apis/master_data/department.api';

interface Props {
  value?: number | undefined;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number | undefined, label: React.ReactNode[], data?: FlatItem) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  isShowsOptionNull?: boolean;
  isSelectedObject?: boolean;
  popupMatchSelectWidth?: number;
}

export default function DepartmentTreeSelect({
  value,
  style,
  allowClear = true,
  onChange,
  disabled,
  className,
  placeholder,
  isShowsOptionNull,
  isSelectedObject = false, // nếu cần lấy object FlatItem
  popupMatchSelectWidth = 400
}: Props) {
  const [departmentTreeItem, setDepartmentTreeItem] = useState<TreeItem[] | undefined>(undefined);

  const departmentsDropdownQuery = useQuery({
    queryKey: ['departmentsTreeDropdown'],
    queryFn: () => getDepartmentsDropdown(),
    staleTime: 60 * 1000 * 60
  });

  useEffect(() => {
    const result = buildHierarchySelect(departmentsDropdownQuery.data?.data);
    if (result && isShowsOptionNull) {
      result?.unshift({
        key: 0,
        value: '0',
        title: 'Không có bộ phận',
        children: []
      });
    }

    setDepartmentTreeItem(result);
  }, [departmentsDropdownQuery.data?.data, isShowsOptionNull]);

  const findTreeNode = (data: TreeItem[], value: number | undefined): TreeItem | null => {
    for (const node of data) {
      if (node.value === String(value)) {
        return node;
      }
      if (node.children) {
        const found = findTreeNode(node.children, value);
        if (found) return found;
      }
    }
    return null;
  };

  const handleChange = (value: number | undefined, labels: React.ReactNode[]) => {
    let selectedItem: TreeItem | null = null;
    if (isSelectedObject) {
      selectedItem = findTreeNode(departmentTreeItem || [], value);
    }

    onChange && onChange(value, labels, selectedItem ? selectedItem?.data : undefined);
  };

  return (
    <TreeSelect
      treeLine={true}
      value={departmentsDropdownQuery.isFetching ? undefined : value}
      disabled={disabled}
      showSearch
      placement='bottomLeft'
      placeholder={placeholder}
      className={className}
      style={style}
      popupMatchSelectWidth={popupMatchSelectWidth ?? 400}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeNodeFilterProp='value'
      allowClear={allowClear}
      onChange={handleChange}
      treeData={departmentTreeItem}
      loading={departmentsDropdownQuery.isFetching}
      filterTreeNode={(inputValue, treeNode) => {
        return treeNode.title?.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ?? false;
      }}
    />
  );
}
