import type { FlatItem } from 'src/types/elements.type';
import type { IFactory, FactoryAdd } from 'src/types/master_data/factory.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/factories';

export const getFactories = async (
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IFactory>>(`${controller}`, {
    params: {
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getFactory = async (id: number) => {
  return http.get<IFactory>(`${controller}/${id}`);
};

export const addFactory = async (factory: FactoryAdd) => {
  return http.post<IFactory>(controller, factory);
};

export const updateFactory = async (id: number, factory: FactoryAdd) => {
  return await http.put<IFactory[]>(`${controller}/${id}`, factory);
};

export const deleteFactory = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getFactoriesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};
