import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const installationPath = {
  installationManagement: '/erp/contract-execution/installation',

  // Installation request - Lệnh lắp đặt
  installationRequest: '/erp/contract-execution/installation/installation-request',
  installationRequestDetail: '/erp/contract-execution/installation/installation-request' + CRUDPathSegmentEn.DETAIL,
  installationRequestAdd: '/erp/contract-execution/installation/installation-request' + CRUDPathSegmentEn.ADD,
  installationRequestEdit: '/erp/contract-execution/installation/installation-request' + CRUDPathSegmentEn.EDIT
};
export default installationPath;
