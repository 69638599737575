import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const rndPath = {
  rnd: '/erp/rnd',

  // Raw Material SBOM - SBOM Nguyên vật liệu
  rawMatPmr: '/erp/rnd/raw-mat-pmr',
  rawMatPmrAdd: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.ADD,
  rawMatPmrDetail: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.DETAIL,
  rawMatPmrDetailCode: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  rawMatPmrEdit: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.EDIT,

  // Labor SBOM - SBOM Nhân công
  laborPmr: '/erp/rnd/labor-pmr',
  laborPmrAdd: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.ADD,
  laborPmrDetail: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.DETAIL,
  laborPmrDetailCode: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  laborPmrEdit: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.EDIT,

  // Equipment SBOM - SBOM công cụ dụng cụ
  equipmentPmr: '/erp/rnd/equipment-pmr',
  equipmentPmrAdd: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.ADD,
  equipmentPmrDetail: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.DETAIL,
  equipmentPmrDetailCode: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  equipmentPmrEdit: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.EDIT,

  // Production Process - Nhóm Quy trình sản xuất
  rndProductionProcessGroup: '/erp/rnd/production-process-group',

  // Production Process - Quy trình sản xuất
  rndProductionProcess: '/erp/rnd/production-process',
  rndProductionProcessAdd: '/erp/rnd/production-process' + CRUDPathSegmentEn.ADD,
  rndProductionProcessDetail: '/erp/rnd/production-process' + CRUDPathSegmentEn.DETAIL,
  rndProductionProcessEdit: '/erp/rnd/production-process' + CRUDPathSegmentEn.EDIT,

  // Item thành phẩm
  itemProductRnd: '/erp/rnd/item-product',
  itemProductRndAdd: '/erp/rnd/item-product' + CRUDPathSegmentEn.ADD,
  itemProductRndEdit: '/erp/rnd/item-product' + CRUDPathSegmentEn.EDIT,
  itemProductRndDetail: '/erp/rnd/item-product' + CRUDPathSegmentEn.DETAIL,

  // Item NVL
  itemMaterialRnd: '/erp/rnd/item-material',
  itemMaterialRndAdd: '/erp/rnd/item-material' + CRUDPathSegmentEn.ADD,
  itemMaterialRndEdit: '/erp/rnd/item-material' + CRUDPathSegmentEn.EDIT,
  itemMaterialRndDetail: '/erp/rnd/item-material' + CRUDPathSegmentEn.DETAIL,

  // Bản thiết kế
  designRnd: '/erp/rnd/design',
  designRndAdd: '/erp/rnd/design' + CRUDPathSegmentEn.ADD,
  designRndEdit: '/erp/rnd/design' + CRUDPathSegmentEn.EDIT,
  designRndDetail: '/erp/rnd/design' + CRUDPathSegmentEn.DETAIL,

  // Item Model
  modelRnd: '/erp/rnd/model',
  modelRndAdd: '/erp/rnd/model' + CRUDPathSegmentEn.ADD,
  modelRndEdit: '/erp/rnd/model' + CRUDPathSegmentEn.EDIT,
  modelRndDetail: '/erp/rnd/model' + CRUDPathSegmentEn.DETAIL,

  // Standard PS
  standardPSRnd: '/erp/rnd/standard-ps',
  standardPSRndAdd: '/erp/rnd/standard-ps' + CRUDPathSegmentEn.ADD,
  standardPSRndEdit: '/erp/rnd/standard-ps' + CRUDPathSegmentEn.EDIT,
  standardPSRndDetail: '/erp/rnd/standard-ps' + CRUDPathSegmentEn.DETAIL,

  // PS
  psRnd: '/erp/rnd/ps',
  psRndAdd: '/erp/rnd/ps' + CRUDPathSegmentEn.ADD,
  psRndEdit: '/erp/rnd/ps' + CRUDPathSegmentEn.EDIT,
  psRndDetail: '/erp/rnd/ps' + CRUDPathSegmentEn.DETAIL,

  // ProductionJobTask
  productionJobTaskRnd: '/erp/rnd/production-jobtask',
  productionJobTaskRndAdd: '/erp/rnd/production-jobtask' + CRUDPathSegmentEn.ADD,
  productionJobTaskRndEdit: '/erp/rnd/production-jobtask' + CRUDPathSegmentEn.EDIT,
  productionJobTaskRndDetail: '/erp/rnd/production-jobtask' + CRUDPathSegmentEn.DETAIL,

  // Acceptance norm - Định mức Nghiệm thu thành phẩm
  acceptanceNorm: '/erp/rnd/acceptance-norm',
  acceptanceNormDetail: '/erp/rnd/acceptance-norm' + CRUDPathSegmentEn.DETAIL,
  acceptanceNormAdd: '/erp/rnd/acceptance-norm' + CRUDPathSegmentEn.ADD,
  acceptanceNormEdit: '/erp/rnd/acceptance-norm' + CRUDPathSegmentEn.EDIT,

  // Set of drawing - Bộ bản vẽ
  setOfDrawing: '/erp/rnd/set-of-drawing',
  setOfDrawingDetail: '/erp/rnd/set-of-drawing' + CRUDPathSegmentEn.DETAIL,
  setOfDrawingAdd: '/erp/rnd/set-of-drawing' + CRUDPathSegmentEn.ADD,
  setOfDrawingEdit: '/erp/rnd/set-of-drawing' + CRUDPathSegmentEn.EDIT
};
export default rndPath;
