import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { getDepartmentsDropdown } from 'src/apis/master_data/department.api';
import type { SelectProps, TreeItem } from 'src/types/elements.type';
import { buildHierarchySelect } from 'src/utils/collection';

export default function DepartmentSelect({ value, onChange, popupMatchSelectWidth, style, ...props }: SelectProps) {
  const [departmentTreeItem, setDepartmentTreeItem] = useState<TreeItem[] | undefined>(undefined);

  const departmentsDropdownQuery = useQuery({
    queryKey: ['departmentsDropdown'],
    queryFn: () => getDepartmentsDropdown(),
    staleTime: 5 * 60 * 1000
  });

  useEffect(() => {
    if (departmentsDropdownQuery.data) {
      const result = buildHierarchySelect(departmentsDropdownQuery.data?.data);
      setDepartmentTreeItem(result);
    }
  }, [departmentsDropdownQuery.data]);

  return (
    <Select
      {...props}
      value={departmentsDropdownQuery.isFetching ? undefined : value}
      style={{ ...style, width: '100%' }}
      popupMatchSelectWidth={popupMatchSelectWidth ?? 400}
      onChange={onChange}
      options={departmentTreeItem?.map((data) => ({
        value: Number(data.value),
        label: data.title,
        data: data
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={departmentsDropdownQuery.isFetching}
    />
  );
}
